import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import { ProfileMenu } from "../home/ProfileMenu";
import { ChatContext } from "../../context/ChatContext";
import { AppContext } from "../../context/AppContext";
import { HISTORY_ZERO_STATE } from "../../constants/HistoryConstants";
import { generateChatSessionId } from "../../utils/chat";
import { ReactComponent as LogoutIcon } from "../../assets/icon-logout.svg";
import { ReactComponent as ChatHistoryIcon } from "../../assets/icon-history.svg";
import { ReactComponent as NewChatIcon } from "../../assets/icon-topic.svg";
import { logout } from "../../services/authentication";

import logo from "../../assets/Elysia-logo.svg"
import "./style.scss";

const Menu = () => {
  const navigate = useNavigate();
  const { setPromptInput, setIsChatActive, setNewChat, setChatSessionId, setClearState, setChatAgent, setActiveChatHistoryRecord, setSessionPath, setIsRestrictedChatSessionStatus, setShowScrollIcon } = useContext(ChatContext);
  const { setIsSideNavOpen } = useContext(AppContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const startNewTopicChat = () => {
    localStorage.setItem("stopStreaming", "false");
    setClearState?.(true);
    setNewChat?.(true);
    setChatSessionId?.(generateChatSessionId());
    setChatAgent?.("Generic");
    setIsChatActive?.(false);
    setShowScrollIcon?.(false);
    setActiveChatHistoryRecord(HISTORY_ZERO_STATE);
    navigate({
      pathname: "/",
      search: queryParams.toString()
    });
    setPromptInput("");
    setSessionPath?.("");
    setIsRestrictedChatSessionStatus?.("");
  };

  const OpenSideNav = () => {
    window.setTimeout(() => {
      setIsSideNavOpen(true);
    }, 0);
  };
 

  return (
    <>
      <Navbar className="custom-nav d-none d-lg-flex">
        <Navbar.Brand
          className="flex items-center cursor-pointer me-0 me-md-auto"
        >
          <Button variant="link" onClick={startNewTopicChat}>
            <img className="gemini-logo" alt="Elysia" src={logo} />
          </Button>
        </Navbar.Brand>
        <div className="text-end">
          <ProfileMenu />
        </div>
      </Navbar>

      <Navbar className="custom-nav d-flex d-lg-none justify-content-between align-items-center">
        <div className="b-block d-lg-none open-sidebar-mobile">
          <Button aria-label="new chat" data-testid="new-chat-icon-button-mobile" className="history-icon-wrapper fw-semibold" onClick={startNewTopicChat}><NewChatIcon /></Button>
          <Button aria-label="chat history" data-testid="chat-history-button-mobile" className="history-icon-wrapper fw-semibold" onClick={OpenSideNav}><ChatHistoryIcon /></Button>
        </div>
        <Navbar.Brand
          className="flex items-center cursor-pointer mobile-branding"
        >
          <Button variant="link" onClick={startNewTopicChat}>
            <img className="gemini-logo" alt="Elysia" src={logo} />
          </Button>
        </Navbar.Brand>
        <div className="logout-icon">
          <LogoutIcon onClick={logout} />
        </div>
      </Navbar>
    </>
  );
};

export default Menu;
