import { marked } from "marked";
import DOMPurify from 'dompurify';
import { v4 as uuidv4 } from 'uuid';

const decodeHTMLEntities = (text) => {
    var entities = {
        amp: "&",
        apos: "'",
        "#x27": "'",
        "#x2F": "/",
        "#39": "'",
        "#47": "/",
        lt: "<",
        gt: ">",
        nbsp: " ",
        quot: '"',
    };
    return text.replace(/&([^;]+);/gm, function (match, entity) {
        return entities[entity] || match;
    });
};

export const formatResponse = (htmlReponse, isEnd) => {
    let formatted = ""
    if (htmlReponse) {
        formatted = htmlReponse.replaceAll("```html", "");
        formatted = formatted.startsWith("<pre>") ? formatted.substring(5) : formatted;
        formatted = formatted.endsWith("</pre>") ? formatted.substring(0, formatted.length - 6) : formatted;
        formatted = formatted.startsWith("<code>") ? formatted.substring(6) : formatted;
        formatted = formatted.endsWith("</code>") ? formatted.substring(0, formatted.length - 7) : formatted;
        formatted = formatted.startsWith("\"") ? formatted.substring(1) : formatted;
        formatted = formatted.endsWith("\"") ? formatted.substring(0, formatted.length - 1) : formatted;
        formatted = formatted.replaceAll("\\n", "");
        formatted = decodeHTMLEntities(formatted)
        formatted = formatted.startsWith("<title>") ? formatted.substring(7) : formatted;
        formatted = formatted.endsWith("</title>") ? formatted.substring(0, formatted.length - 8) : formatted;
        // commenting this code to check later
        // const preIndex = formatted.indexOf("<pre>");
        // if (preIndex !== -1) {
        //     const closingTagIndex = formatted.indexOf("</pre>", preIndex);
        //     if (closingTagIndex !== -1) {
        //         formatted = formatted.substring(0, preIndex) + formatted.substring(closingTagIndex + 6);
        //     }
        // }
        if (isEnd) {
            formatted = formatted.replaceAll("```", "")
            formatted = formatted.replaceAll("\"(\\r\\n|\\n|\\r)/g)}", "");
            formatted = formatted.endsWith("}") ? formatted.substring(0, formatted.length - 1) : formatted;
            formatted = formatted.endsWith("\"") ? formatted.substring(0, formatted.length - 1) : formatted;
        }
        formatted = formatted.endsWith("\"") ? formatted.substring(0, formatted.length - 1) : formatted;
    }
    // console.log('formatted', formatted);
    return formatted;
}

// We're adding the phrase "Your response should be formatted using HTML tags." along with an additional "." to the user's query in the streaming API.
// Consequently, it's stored as is in the history. We must exclude this when displaying the active chat history
// TODO: We can remove this logic once this phrase is sent as part of the instructions insted of user query
// The phrase is now removed from the input query, but to support older chats and archived chats which might have
// this phrase, we are keeping this code.
export const formatInput = (inputText: String) => {
    inputText = inputText.replace(/Your response must be formatted using HTML tags./i, "").trim();
    return inputText;
}

export const formatMarkdown = (markdownResponse) => {
    return markdownResponse.replace(/\\n/gi, '\n ');
}

export const generateChatSessionId = () => {
    return uuidv4();
}

export const convertMarkdownToPlain = (markdown) => {
    let html = marked(markdown);
    html = DOMPurify.sanitize(html);
    if (typeof html !== 'string') {
        html = '';
    }
    const tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || "";
};
