import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ChatHistorySidebar from "./ChatHistorySidebar";
import { ChatContext } from "../../context/ChatContext";
import { AppContext } from "../../context/AppContext";
import { HISTORY_ZERO_STATE } from "../../constants/HistoryConstants";
import { ReactComponent as ChatHistoryIcon } from "../../assets/icon-history.svg";
import { ReactComponent as NewChatIcon } from "../../assets/icon-topic.svg";
import "./style.scss";
import { generateChatSessionId } from "../../utils/chat";
import OverlayTooltip from "./UI/OverlayTooltip";

const Sidebar = () => {
    const { isSideNavOpen, setIsSideNavOpen } = useContext(AppContext);
    const { setPromptInput, setIsChatActive, setNewChat, setChatSessionId, setClearState, setChatAgent, setActiveChatHistoryRecord, setSessionPath, setIsRestrictedChatSessionStatus, setShowScrollIcon } = useContext(ChatContext);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const startNewTopicChat = () => {
        setShowScrollIcon?.(false);
        localStorage.setItem("stopStreaming", "false");
        setClearState?.(true);
        setNewChat?.(true);
        setChatSessionId?.(generateChatSessionId());
        setChatAgent?.("Generic");
        setIsChatActive?.(false);
        setActiveChatHistoryRecord(HISTORY_ZERO_STATE);
        navigate({
            pathname: "/",
            search: queryParams.toString()
        });
        setPromptInput("");
        setSessionPath?.("");
        setIsRestrictedChatSessionStatus?.("");
    };

    return (
        <>
            <ChatHistorySidebar startNewTopicChat={startNewTopicChat} />
            <div className={`btn-container ${(isSideNavOpen ? "d-none" : "")}`}>
                <OverlayTooltip
                    tooltipText="New Chat"
                    buttonContent={<NewChatIcon />}
                    ariaLabel="new chat"
                    testId="new-chat-icon-button"
                    tooltipClassName="tooltip-sidebar"
                    buttonClassName="history-icon-wrapper fw-semibold"
                    onClick={startNewTopicChat}
                />
                <br />
                <OverlayTooltip
                    tooltipText="Chat History"
                    buttonContent={<ChatHistoryIcon />}
                    ariaLabel="chat history"
                    testId="chat-history-button"
                    tooltipClassName="tooltip-sidebar"
                    buttonClassName="history-icon-wrapper fw-semibold"
                    onClick={() => setIsSideNavOpen(!isSideNavOpen)}
                />
            </div>
        </>
    );
};

export default Sidebar;
