import axios from "axios";
import { catchError } from "./common";
import qs from "qs";

export const getSources = async (
  myContentOnlyFlag: string,
  context?: string,
  searchTerm?: string,   
  sortOrder?:string,
  limit?: string,
  offset?: string
): Promise<SourceList> => {
  try {    
    let data = {my_contents_only : myContentOnlyFlag};
    if (context) data['context'] = context;
    if (searchTerm) data['search_term'] = searchTerm;
    if (limit) data['limit'] = limit;
    if (offset) data['offset'] = offset;
    if (sortOrder) data['sort_order'] = sortOrder;
    const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}v1/ai/content/sources?${qs.stringify(data)}`);
    return response.data.data;
  } catch (e: any) {
    console.log(e);
    catchError(e);
    //return not used, keep typescript happy
    let retObj: any;       
    return retObj;
  }
};

export const deleteSource = async (source_id: string): Promise<any> => {
  try {    
    await axios.delete(`${process.env.REACT_APP_API_DOMAIN}v1/ai/content/source/${source_id}`);
    return {"delete":"success"};
  } catch (e: any) {
    console.log(e);
    catchError(e);    
    return {"delete":"error"};
  }
};

export const getContentSignedUrl = async (collectionType: string, collectionProductLine: string, fileName: string, title: string): Promise<any> => {
  try {    
    let data = {type: collectionType , product_line: collectionProductLine, id : fileName, title: title}
    const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}v1/ai/content/url`, data);
    return response.data.data;
  } catch (e: any) {
    console.log(e);
    catchError(e);
    //return not used, keep typescript happy
    let retObj: any;       
    return retObj;
  }
}

export const uploadFileUsingUrl = async (url: string, file: File): Promise<any> => {
  try {    
    const requestOptions = {
        method: 'PUT',
        body: file
    };
    const response = await fetch(url, requestOptions);  
    return response;
  } catch (e: any) {
    console.log(e);
    catchError(e);
    throw e;
  }
}

export const triggerIngestion = async (path: string, title: string): Promise<any> => {
  try {    
    let data = {path: path, title: title}
    const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}v1/ai/content/ingestions`, data);
    return response.data.data;
  } catch (e: any) {
    console.log(e);
    catchError(e);
    //return not used, keep typescript happy
    let retObj: any;       
    return retObj;
  }
};


