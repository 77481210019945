import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { ReactComponent as DeleteIcon } from "../../assets/icon-google-delete.svg";
import { ReactComponent as ElysiaBrainIcon } from "../../assets/icon-elysia-brain.svg";

const DeleteModal = (props) => {
    const { deleteSourceById, documnetID } = props;
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDeleteDoc = () => {
        deleteSourceById(documnetID);
        handleClose();
    };

    return (
        <>
            <Button
                className="content-delete-option"
                title="Delete"
                onClick={handleShow}
            >
                <DeleteIcon />
            </Button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="delete-modal"
                backdropClassName="delete-modal-backdrop"
                data-testid="delete-file-modal"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title data-testid="delete-item">Delete item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ElysiaBrainIcon />
                    <span data-testid="delete-modal-sub-heading">You have chosen to delete this file, it will be deleted forever.</span>
                    <br />
                    <span data-testid="delete-file-modal-confirmation" className="delete-confirm-msg">Are you sure you want to delete it?</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="modal-cancel" data-testid="delete-modal-cancel-button" variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button className="modal-proceed" data-testid="delete-modal-proceed-button" variant="primary" onClick={handleDeleteDoc}>
                        Proceed
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteModal;
