import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { getSamplePrompts } from "../../services/savedPrompts";
import { LoadingContext } from "../../context/LoadingContext";
import { ChatContext } from "../../context/ChatContext";
import { PromptContext } from "../../context/PromptContext";
import { ReactComponent as ChatIcon } from "../../assets/icon-sample-chat.svg";
import { HISTORY_ZERO_STATE } from "../../constants/HistoryConstants";
import { generateChatSessionId } from "../../utils/chat";

interface SamplePrompt {
  prompt: string;
  prompt_id: number;
}

export const SamplePrompts = () => {
  const navigate = useNavigate();
  const [sampleTopics, setSampleTopics] = useState<SamplePrompt[]>([]);
  const { isLoading } = useContext(LoadingContext);
  const { setPrompt, setProcessed } = useContext(PromptContext);
  const { setPromptInput, setChatSessionId, setNewChat, setClearState, setChatAgent, setActiveChatHistoryRecord, setSessionPath, setIsRestrictedChatSessionStatus, setIsStreamingRequested } =
    useContext(ChatContext);

  const startNewSavedPromptChat = (promptValue) => {
    localStorage.setItem("stopStreaming", "false");
    setIsStreamingRequested?.(true);
    setClearState?.(true);
    setNewChat?.(true);
    setChatSessionId?.(generateChatSessionId());
    setChatAgent?.("Generic");
    setActiveChatHistoryRecord(HISTORY_ZERO_STATE);
    navigate("/");
    setPromptInput("");
    setSessionPath?.("");
    setPrompt?.(promptValue);
    setProcessed?.(false);
    setIsRestrictedChatSessionStatus?.("");
  };

  useEffect(() => {
    (async () => {
      let response;
      try {
        response = await getSamplePrompts();
      } catch (err) {
        console.error("error fetching sample prompts");
      }
      if (response) {
        setSampleTopics(response);
      }
    })();
  }, [])

  return (
    <>
      <div className="nav-box">
        {sampleTopics.map(topic => (
          <Button disabled={isLoading} className="sample-prompt-card" title={topic.prompt} onClick={() => { startNewSavedPromptChat(topic.prompt) }}>
            <div className="d-flex sample-prompt-card-body">
              <ChatIcon className="chat-save-icon" />
              <div className="sample-prompt-text">
                {topic.prompt}
              </div>
            </div>
          </Button>
        ))}
      </div>
    </>
  );
};
