import { createContext } from 'react';

const initialSettingContext: SettingContextStore = { 
    modelProvider: "",
    modelName: "",
    personality: "",
    creativity: "",
    role: "",
    domainExpertise: "",
    writingStyle : "",
    outputLanguage: "",
    showSourceList: true,
    setModelProvider: () => {},
    setModelName: () => {},
    setPersonality: () => {},
    setCreativity:() => {},
    setRole: () => {},
    setDomainExpertise: () => {},
    setWritingStyle : () => {},
    setOutputLanguage: () => {}, 
    setShowSourceList: () => {}   
}

export const SettingContext = createContext<SettingContextStore >(initialSettingContext);