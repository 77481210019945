import { useContext } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { ChatContext } from "../../context/ChatContext";


export const BackToChatButton = () => {

  const navigate = useNavigate();
  const { sessionPath, isPrivateChat } = useContext(ChatContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const backToChat = () => {
    if (sessionPath && sessionPath !== "") {
      navigate(sessionPath);
    } else {
      if (isPrivateChat) {
        queryParams.set("private-chat", "true");
      }
      navigate({
        pathname: "/",
        search: queryParams.toString()
      });
    }
  }

  return (
    <div className="d-flex justify-content-end mt-5">
      <Button onClick={backToChat} className="back-to-chat" data-testid="back-to-chat-button">
        <div className="back-to-chat-text">Back to Chat</div>
      </Button>
    </div>
  );
}