import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../../context/ChatContext";
import { AuthContext } from "../../context/AuthContext";
import { logout } from "../../services/authentication";
import { getUserInitials } from "../../utils/user";
import { CommonDropdown } from "../common/UI/CommonDropdown";
import { menuConfig } from "./menuConfig";
import { CommonDropdownProps } from "../common/UI/CommonDropdown";

export const ProfileMenu = () => {
  const navigate = useNavigate();
  const { allowedRoutes =[] } = useContext(ChatContext);
  const user = useContext(AuthContext);
  const initials = getUserInitials(user?.username);

  const isDashboardRouteAllowed = allowedRoutes?.includes("/dashboard");
  const isSandboxRouteAllowed = allowedRoutes?.includes("/sandbox");

  const config = menuConfig(navigate, logout, initials, isSandboxRouteAllowed, isDashboardRouteAllowed);

  return (
    <div className="profile-menu">
      {Object.values(config)
        .filter((item): item is CommonDropdownProps => !!item) 
        .map((menuProps, idx) => (
          <CommonDropdown key={idx} {...menuProps} />
        ))}
    </div>
  );
};
