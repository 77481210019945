import { Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';

import { ReactComponent as CloseIcon } from "../../assets/icon-close.svg";
import { ReactComponent as SearchIcon } from "../../assets/icon-google-search.svg";

const DocSourceListHeader = (props) => {
    const { selectAllSources, selectNoSources, handleClose, searchTerm, setSearchTerm } = props;

    return (
        <div className="src-list-header">
            <div className="d-flex justify-content-between">
                <Button data-testid="select-all-sources-button" aria-label="select all" className="chat-menu-src-select-link" onClick={() => selectAllSources()}>Select All</Button>
                <Button data-testid="select-none-sources-button" aria-label="select none" className="chat-menu-src-select-link" onClick={() => selectNoSources()}>Select None</Button>
                <Button
                    className="close-chat-src-popup mt-1"
                    data-testid="close-add-sources-popup"
                    title="Close"
                    aria-label="close"
                    onClick={() => { handleClose() }}
                >
                    <CloseIcon />
                </Button>

            </div>
            <div className="search-source-wrapper">
                <span className="search-icon"><SearchIcon /></span>
                <Form.Control
                    type="text"
                    className="search-source-input"
                    data-testid="search-source-content-input"
                    placeholder="Search content"
                    aria-label="search content"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
        </div>
    )
};

export default DocSourceListHeader;
