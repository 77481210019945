import icon_shield from "../../assets/icon-shield.svg";
import "./style.scss";

const SystemProtectionMessage = () => {
    return (
        <div className="sys-msg-body sys-protection-msg">
            <img
                className="sys-msg-card-img"
                src={icon_shield}
                alt="icon"
            />
            <span className="sys-msg-card-title">
                {" "}
                Your personal and company data are protected in this chat{" "}
            </span>
        </div>
    );
};

export default SystemProtectionMessage;
