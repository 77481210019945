import { useEffect, useState } from "react";
import { About } from "./About";
import { SkillAndInterests } from "./SkillsAndInterests";
import { ProfileDetails } from "./ProfileDetails";
import { BackToChatButton } from "../common/BackToChatButton";

import { getMyProfile, updateMyProfile } from "../../services/profile";

import icon_loading from "../../assets/elysia-loader.gif";

const MyProfile = () => {

    const [loading, setLoading] = useState(false);
    const [pageState, setPageState] = useState("all")
    const [profileInfo, setProfileInfo] = useState<ProfileInfo>();

    const handleInvalidData = (val) => {
        return val === undefined || val === "nan" ? "" : val;
    }

    useEffect(() => {
        (async () => {
            let response;
            try {
                setLoading(true);
                response = await getMyProfile();
                setLoading(false);
            } catch (err) {
                setLoading(false);
                console.log("error fetching my profile");
            }
            if (response && response[0]) {
                setProfileInfo({
                    fullName: handleInvalidData(response[0].doc.full_name),
                    email: response[0].doc.email,
                    employee_number: response[0].id,
                    optOut: response[0].doc.opted_out,
                    photo: handleInvalidData(response[0].doc.photo),
                    about: handleInvalidData(response[0].doc.about),
                    linkedin: handleInvalidData(response[0].doc.linkedin),
                    skills: response[0].doc.skills,
                    interests: response[0].doc.topics_of_interest,
                });
            }

        })();
    }, []);



    const updateProfileInfo = async (profileInfoObj) => {
        setProfileInfo(profileInfoObj);
        try {
            const response = await updateMyProfile(profileInfoObj);
            return response;
        } catch (err) {
            console.log("error fetching my profile");
            return null;
        }
    }

    return (
        <>
            <BackToChatButton />
            {
                pageState === "all" && (
                    <>
                        <div className="d-flex justify-content-between mb-4">
                            <h1 data-testid="profile-title" className="title1 my-profile-title mb-0">My Profile</h1>
                        </div>

                        <p className="mb-4" data-testid="profile-description">
                            Manage your info, privacy and security settings to make Elysia work better for you
                        </p>

                    </>
                )
            }

            {
                pageState === "all" && loading && (
                    <>
                        <div className="gap">&nbsp;</div>
                        <div className="text-center">
                            <img src={icon_loading} alt="loading" />
                        </div>
                    </>
                )
            }
            {
                !loading && (
                    <>
                        <About profileInfo={profileInfo} updateProfileInfo={updateProfileInfo} pageState={pageState} />
                        <ProfileDetails profileInfo={profileInfo} updateProfileInfo={updateProfileInfo} pageState={pageState} setPageState={setPageState} />
                        <SkillAndInterests profileInfo={profileInfo} updateProfileInfo={updateProfileInfo} pageState={pageState} page="skills" />
                        <SkillAndInterests profileInfo={profileInfo} updateProfileInfo={updateProfileInfo} pageState={pageState} page="interests" />
                    </>

                )
            }

        </>
    );
};

export default MyProfile;