import { createContext } from 'react';
import { HISTORY_ZERO_STATE } from "../constants/HistoryConstants";

const initialChatContext: ChatContextStore = {
    clearState: false,
    chatAgent: '',
    nextBestActions: [],
    context: '',
    sources: [],
    refreshSources: 0,
    includeSearch: true,
    allowedCollections: [],
    allowedRoutes: [],
    selectedSources: [],
    collection: '',
    collectionType: '',
    collectionProductLine: '',
    collectionName: '',
    activeChatHistoryRecord: HISTORY_ZERO_STATE,
    isActiveChatHistoryLoading: false,
    isNewSession: false,
    sessionPath: '',
    backToChatSession: false,
    newChat: true,
    chatSessionId: '',
    isRestrictedChatSessionStatus: "",
    isChatActive: false,
    promptInput: "",
    isStreamingRequested: false,
    dynamicStreamCount: 0,
    setClearState: () => { },
    setChatAgent: () => { },
    setNextBestActions: () => { },
    setContext: () => { },
    setSources: () => { },
    setRefreshSources: () => { },
    setIncludeSearch: () => { },
    setAllowedCollections: () => { },
    setAllowedRoutes: () => { },
    setSelectedSources: () => { },
    setCollection: () => { },
    setCollectionType: () => { },
    setCollectionProductLine: () => { },
    setCollectionName: () => { },
    setActiveChatHistoryRecord: () => { },
    setIsActiveChatHistoryLoading: () => { },
    setIsNewSession: () => { },
    setSessionPath: () => { },
    setBackToChatSession: () => { },
    setNewChat: () => { },
    setChatSessionId: () => { },
    setIsRestrictedChatSessionStatus: () => { },
    setIsChatActive: () => { },
    setPromptInput: () => { },
    setIsStreamingRequested: () => { },
    setDynamicStreamCount: () => { },
}

export const ChatContext = createContext<ChatContextStore>(initialChatContext);