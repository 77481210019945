import React, { useContext, useEffect } from "react";
import MySettings from "../components/configurations/MySettings";
import { ChatContext } from "../context/ChatContext";

const MySettingsPage = ({ contentRef }) => {

  const { setBackToChatSession } = useContext(ChatContext);

  useEffect(() => {
    setBackToChatSession?.(true);
    if (window.location.pathname !== '/sandbox') {
      const containerElt = Array.from(
        document.getElementsByClassName('main-container') as HTMLCollectionOf<HTMLElement>,
      );
      containerElt.forEach(box => {
        box.style.overflowY = 'auto';
        box.style.marginTop = "20px";
      });
    }
     // eslint-disable-next-line  
  }, []);
  return (
    <>
      <div ref={contentRef} id="main-content" className="content-scrollable overflow-auto flex-grow-1 d-flex justify-content-center me-2 mt-4 chat-interface">
        <div className="width-control">
          <MySettings />
        </div>
      </div>
    </>
  );
};

export default MySettingsPage;
