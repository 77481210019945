export const isValidHttpUrl = (str) => {
    try {
        const newUrl = new URL(str);
        return ['http:', 'https:'].includes(newUrl.protocol);
    } catch (err) {
        return false;
    }
}

// TODO: check if this can be improved
export const isValidLinkedinUrl = (url) => {
    if (url === "") {
        return false;
    }
    if (!isValidHttpUrl(url) && !url.includes("linkedin.com/in")) {
        return false;
    }
    return true;
}

//TODO: implement fall back to show placeholder
export const getFavicon = (url) => {
    const domain = new URL(url).hostname;
    return `https://www.google.com/s2/favicons?domain=${domain}`;
}