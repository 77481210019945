import './style.scss'

const MainHeader = () => {

  const greeting = () => {
    const now = new Date();
    const hour = now.getHours();
    if (hour < 12) {
      return "Good morning.";
    } else if (hour < 18) {
      return "Good afternoon.";
    } else {
      return "Good evening.";
    }
  };

  return (
    <div className="header-container">
      <div className="header-content">
        <div className="header-greeting">{greeting()}</div>
        <h1 className="mb-0 header-greeting-1">How can I assist you today?</h1>
      </div>
    </div>
  )

};


export default MainHeader;