import React from "react";
import { Button } from "react-bootstrap";
import { ReactComponent as IconRight } from "../../assets/icon-select-option.svg";
import icon_elysia_brain from "../../assets/icon-elysia-brain.svg";

interface ProfileDetailsViewProps {
    profileInfo: any;
    setPageState: (state: string) => void;
}

const ProfileDetailsView = ({ profileInfo, setPageState }: ProfileDetailsViewProps) => {
    return (
        <div className="menu-option-box p-4 mb-4">
            <div className="d-flex justify-content-between align-items-baseline">
                <p data-testid="profile-section-details" className="mb-0">Details</p>
            </div>
            <div data-testid="details-disclaimer" className="d-flex align-items-center system-default-message">
                <img src={icon_elysia_brain} alt="Elysia" /> This content was generated by Elysia based on your profile.
            </div>
            <div className="mt-2">
                <div className="profile-row">
                    <div data-testid="fullname-key" className="column-1">Full Name</div>
                    <div data-testid="fullname-value" className="column-2">{profileInfo?.fullName}</div>
                </div>
                <div className="profile-row">
                    <div data-testid="email-key" className="column-1">Email</div>
                    <div data-testid="email-value" className="column-2">{profileInfo?.email}</div>
                </div>
                <div className="profile-row">
                    <div data-testid="optin-key" className="column-1">Opt-In </div>
                    <div data-testid="optin-value" className="column-2">{profileInfo?.optOut ? "No" : "Yes"}</div>
                    <Button aria-label="Select Opt-in" data-testid="select-opt-in" onClick={() => {
                        setPageState("optIn");
                    }}>
                        <IconRight />
                    </Button>
                </div>
                <div className="profile-row">
                    <div data-testid="social-key" className="column-1">Social Networks</div>
                    <div data-testid="social-value" className="column-2">LinkedIn</div>
                    <Button aria-label="Select Social Network" data-testid="select-social-network" onClick={() => {
                        setPageState("social");
                    }}>
                        <IconRight />
                    </Button>
                    {profileInfo?.linkedin && <div data-testid="social-disclaimer" className="profile-info-message">
                        One or more accounts synced with Elysia
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default ProfileDetailsView;
