import Form from 'react-bootstrap/Form';

import icon_loading from "../../assets/elysia-loader.gif"
import icon_upload from "../../assets/icon-upload-transparent.svg";

import "./style.scss";

const SaveContent = (props) => {
    const { register, setPageState, setUploadedFileTitle, loading, uploadFile, collectionName } = props;

    return (
        <>
            <div className="title1 color-primary">Save Content</div>
            {
                loading && (
                    <>
                        <div className="gap">&nbsp;</div>
                        <div className="loading text-center">
                            <img src={icon_loading} alt="loading" />
                        </div>
                    </>
                )
            }
            <div className='my-4'>Collection: {collectionName}</div>
            <Form>
                <Form.Group className="mb-3" controlId="title">
                    <Form.Label>Content name <span className="mandatory">*</span></Form.Label>
                    <Form.Control {...register("uploadedfile-title")} type="text" onChange={(event) => setUploadedFileTitle(event.target.value)} />
                </Form.Group>
            </Form>
            <div className='d-flex justify-content-between'>
                <div><button onClick={() => setPageState("manage")} className="button-secondary">Cancel</button></div>
                <div><button onClick={() => uploadFile()} >Save &nbsp;<img src={icon_upload} alt="upload" /></button></div>
            </div>
        </>
    );
};

export default SaveContent;
