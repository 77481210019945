import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Button, Form, FormControl, InputGroup, ListGroup } from 'react-bootstrap';
import icon_loading from "../../assets/elysia-loader.gif";
import { ReactComponent as SearchIcon } from "../../assets/icon-google-search.svg";
import { FixedSizeList as List } from 'react-window';

interface MultiSelectComponentProps {
    loading: boolean;
    options: string[];
    page: string;
    selectedOptions: string[];
    setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
    onAddOptions: () => void;
    onCreateOption: (option: string) => void;
    placeholderText: string;
}

const MultiSelectComponent = ({
    loading,
    options,
    page,
    selectedOptions,
    setSelectedOptions,
    onAddOptions,
    onCreateOption,
    placeholderText
}: MultiSelectComponentProps) => {

    const [inputValue, setInputValue] = useState<string>('');
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [filteredOptions, setFilteredOptions] = useState<string[]>(options);
    const [openUpwards, setOpenUpwards] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const listGroupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                setShowDropdown(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    useEffect(() => {
        // Open the dropdown upwards if it goes beyond the screen

        const listWrapper = listGroupRef.current;

        if (listWrapper && showDropdown) {
            const { bottom } = listWrapper.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const pageMarginBotton = 50;
            const isOutOfViewport = bottom > viewportHeight - pageMarginBotton;
            setOpenUpwards(isOutOfViewport);
        } else {
            setOpenUpwards(false); // set downwards by default
        }
    }, [showDropdown]);

    useEffect(() => {
        const handler = setTimeout(() => {
            const filtered = options.filter(option =>
                option.toLowerCase().includes(inputValue.toLowerCase())
            );
            setFilteredOptions(filtered);
        }, 300);

        // Cleanup the timeout if the effect runs again before the delay finishes
        return () => {
            clearTimeout(handler);
        };
    }, [inputValue, options]);

    const handleCheckboxChange = (option: string) => {
        setSelectedOptions((prevSelectedOptions) => {
            if (prevSelectedOptions.includes(option)) {
                return prevSelectedOptions.filter(selectedOption => selectedOption !== option);
            } else {
                return [...prevSelectedOptions, option];
            }
        });
    };

    const handleCreateOption = () => {
        onCreateOption(inputValue);
        setShowDropdown(false);
        setInputValue('');
    };

    const Row = useMemo(() => ({ index, style }) => {
        const option = filteredOptions[index];
        return (
            <ListGroup.Item key={option} style={style}>
                <Form.Check type="checkbox" className="mb-2">
                    <Form.Check.Input
                        checked={selectedOptions.some(selectedOption => selectedOption === option)}
                        onChange={() => handleCheckboxChange(option)}
                        id={`checkbox-${option}`}
                    />
                    <Form.Check.Label htmlFor={`checkbox-${option}`}>
                        {option}
                    </Form.Check.Label>
                </Form.Check>
            </ListGroup.Item>
        );
        // eslint-disable-next-line 
    }, [filteredOptions, selectedOptions]);

    return (
        <div className='multi-select-dropdown' ref={wrapperRef}>
            <InputGroup className="mb-3">
                <span className='input-icon-container'>
                    <SearchIcon />
                </span>
                <FormControl
                    placeholder={placeholderText}
                    value={inputValue}
                    data-testid={`search-${page}`}
                    type='text'
                    onFocus={() => setShowDropdown(true)}
                    onChange={(e) => setInputValue(e.target.value)}
                />
            </InputGroup>
            <ListGroup ref={listGroupRef} className={`${showDropdown ? '' : 'd-none'} ${openUpwards ? 'open-upwards' : ''}`}>
                {loading && <div className="text-center pb-2">
                    <img src={icon_loading} alt="loading" />
                </div>}

                {!loading && <>
                    {filteredOptions.length > 0 && <>
                        <div className="button-container d-flex justify-content-between flex-row-reverse">
                            <Button data-testid={`${page}-add`} variant="primary" className="light-with-border" onClick={onAddOptions}>Add</Button>
                        </div>
                        <div data-testid={`${page}-dialog`} className="list-group-item-container">
                            <List
                                height={250}
                                itemCount={filteredOptions.length}
                                itemSize={35}
                                width={'100%'}
                            >
                                {Row}
                            </List>
                        </div>
                    </>}
                    {!filteredOptions.length &&
                        <div className="list-group-item-container">
                            <Button data-testid={`${page}-create`} className='create-option' onClick={() => handleCreateOption()}>Create "{inputValue}"</Button>
                        </div>
                    }
                </>}
            </ListGroup>
        </div>
    );
};

export default MultiSelectComponent;
