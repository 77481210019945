import { Button } from "react-bootstrap";

import { ReactComponent as CloseIcon } from "../../assets/icon-close.svg";
import icon_apps from "../../assets/icon-google-apps.svg";

const ChatWithDocHeader = (props) => {
    const { setShowDocPopup } = props;
    return (
        <div className="d-flex">
            <div>
                <img src={icon_apps} alt="apps" data-testid="grid-icon" />
            </div>
            <div className="ps-2 flex-grow-1" data-testid="data-collection-to-include">Data collection to include</div>
            <Button
                className="close-data-collection"
                aria-label="close"
                data-testid="close-chat-with-documents-popup"
                title="Close"
                onClick={() => { setShowDocPopup(false) }}
            >
                <CloseIcon />
            </Button>
        </div>
    );
};

export default ChatWithDocHeader;
