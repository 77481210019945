import React, { useEffect, FC } from 'react';
import { checkUser } from '../services/authentication';
import { AuthContext } from '../context/AuthContext';

interface Props {
    children: JSX.Element,
    user: any,
    setUser: (user: AuthContextStore) => void;
}

export const AuthProvider:FC<Props> = ({ children, user, setUser }) => {
    const getUserData = async() => {       
        const getUser = await checkUser();
        if (getUser) {
            setUser(getUser);
        }
    }
    useEffect(() => {    
        getUserData();
        const interval = setInterval(() => {           
            getUserData()
        }, 600000); //refresh session every 10 min , 1800000
        return () => {
            clearInterval(interval);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <AuthContext.Provider value={user}>
            { user ? 
                 children
                : ''
            }
        </AuthContext.Provider>
    )
}