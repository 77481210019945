import icon_document from "../../assets/icon-google-document.svg";

const nav_items = [
  {
    agent: "Document",
    icon: icon_document,
    title: "Chat with documents",
    desc: "Start a new conversation with Elysia using your uploaded content.",
    prompt: "Okay, you want to start a new conversation using the uploaded content in {collection_name}. What would you like to know?",
    nextBestActions: [],
    placeHolderText: "Chat with document(s)"
  }
];

export default nav_items;