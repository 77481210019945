export const getFirstNameLastNameArray = (val: any) => {
  if (val) {
    return val.replace(/Azure-SSO_/, '').replace(/@.*$/, '').split('.');
  }
  return [''];
};

export const getUserEmail = (val: any) => {
  if (val) {
    val = val.replace(/Azure-SSO_/, '');
    return val
  }
  return [''];
};

export const getUserInitials = (val: string | undefined) => {
  if (val) {
    const userName: string[] = getFirstNameLastNameArray(val);
    const firstLetters: string[] = userName.slice(0, 2).map((name: string) => name.charAt(0));
    return firstLetters.join('');
  }
  return '';
};