import { ChangeEvent, useEffect, useRef, useState } from "react";

import Form from 'react-bootstrap/Form';

import icon_elysia_brain from "../../assets/icon-elysia-brain.svg";
import notify from "../../services/notify";
import { ReactComponent as IconEdit } from "../../assets/icon-edit.svg";
import { Button } from "react-bootstrap";

export const About = ({ profileInfo, updateProfileInfo, pageState }) => {

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [disclaimerText, setDisclaimerText] = useState<string>('');
  const textareaRef = useRef(document.createElement('textarea'));
  const maxInputHeight = 8 * 22;

  useEffect(() => {
    setInputValue(profileInfo?.about);

    if (profileInfo?.about) {
      setDisclaimerText('This content was generated by Elysia based on your profile');
    } else {
      setDisclaimerText('Please connect a social network to generate your profile or alternatively manually populate');
    }
  }, [profileInfo]);

  useEffect(() => {
    if (!isEditMode || pageState !== 'all') { return }
    textareaRef.current.style.height = 'auto'

    if (textareaRef.current.scrollHeight > maxInputHeight) {
      textareaRef.current.style.height = `${maxInputHeight}px`;
      textareaRef.current.style.overflowY = 'auto'
    } else {
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      textareaRef.current.style.overflowY = 'hidden'
    }
    //eslint-disable-next-line
  }, [inputValue, isEditMode, pageState]);

  const handleInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const enteredInput = event.target.value;
    setInputValue(enteredInput);
  }

  const enableEditMode = () => {
    setIsEditMode(true);
  }

  const save = async () => {
    let newProfileInfo = { ...profileInfo }
    newProfileInfo.about = inputValue;
    const response = await updateProfileInfo(newProfileInfo);
    if (response) {
      notify({
        message: 'Your Elysia profile has been successfully updated.',
        type: 'success'
      });
      setIsEditMode(false);
    }
  }

  const reset = () => {
    setInputValue(profileInfo?.about);
    setIsEditMode(false);
  }

  return (
    <>
      {pageState === "all" && (
        <div className="menu-option-box p-4 mb-4">
          <div className="d-flex justify-content-between align-items-baseline">
            <p className="mb-0" data-testid="profile-section-about">About</p>
            {!isEditMode && <Button className="edit" aria-label="Edit About" data-testid="edit-about" onClick={enableEditMode}>
              <IconEdit />
            </Button>}
          </div>
          <div data-testid="about-disclaimer" className="d-flex align-items-center system-default-message">
            <img src={icon_elysia_brain} alt="Elysia" /> {disclaimerText}
          </div>
          {!isEditMode && profileInfo?.about && <p data-testid="about-information" className="about mb-0">{profileInfo?.about}</p>}
          {isEditMode && <Form.Control
            className="edit-about"
            name="edit-about"
            as="textarea"
            data-testid="about-input-field"
            placeholder="Write your profile..."
            value={inputValue}
            rows={1}
            ref={textareaRef}
            onInput={handleInput}
          />}
          {isEditMode && <div className="button-container d-flex justify-content-between">
            <Button data-testid="cancel-about-edit" variant="primary" className="light-without-border" onClick={reset}>
              Cancel
            </Button>
            <Button data-testid="save-about" variant="primary" className="light-with-border" onClick={save}>Save</Button>
          </div>}
        </div>
      )}
    </>
  );
};