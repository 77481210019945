import { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { parse } from 'date-fns';

import { ChatContext } from "../../context/ChatContext";
import { getSources } from "../../services/content";
import { HISTORY_ZERO_STATE } from "../../constants/HistoryConstants";
import ChatWithDocHeader from "./ChatWithDocHeader";
import DocSourceList from "./DocSourceList";
import DocSourceListHeader from "./DocSourceListHeader";
import { generateChatSessionId } from "../../utils/chat";

import { ReactComponent as SourceBackIcon } from "../../assets/icon-backwards.svg";
import icon_arrow from "../../assets/icon-arrow-forward.svg";
import icon_loading from "../../assets/elysia-loader.gif"

import "./style.scss";

export const ChatWithDoc = (props) => {
    const {
        chatAgent,
        setClearState,
        setContext,
        setContextName,
        setSources,
        allowedCollections,
        selectedSources,
        setSelectedSources,
        setActiveChatHistoryRecord,
        collectionName,
        setCollectionName,
        collection,
        setCollection,
        collectionType,
        setCollectionType,
        collectionProductLine,
        setCollectionProductLine,
        context,
        setSessionPath,
        setNewChat,
        setChatSessionId,
        setIsRestrictedChatSessionStatus,
        setPromptInput,
        setIsChatActive,
    } = useContext(ChatContext);

    const { setShowDocPopup } = props;

    const [collectionDefaultValue, setCollectionDefaultValue] = useState("private||My Private Collection|myContentsOnly");
    const [collectionSources, setCollectionSources] = useState<SourceType[]>([]);
    const [filteredCollectionSources, setFilteredCollectionSources] = useState<SourceType[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [isSourcesOpen, setIsSourcesOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { register, setValue } = useForm();

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const docPopupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (searchTerm !== "") {
            setFilteredCollectionSources(
                collectionSources.filter(source =>
                    source.title.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        } else {
            setFilteredCollectionSources(collectionSources);
        }
    }, [searchTerm, collectionSources]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (docPopupRef.current && !docPopupRef.current.contains(event.target as Node)) {
                setShowDocPopup(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [docPopupRef, setShowDocPopup]);

    useEffect(() => {
        setValue("collection-list", collectionType + "|" + collectionProductLine + "|" + collectionName + "|" + collection);
        setContext?.(collection || '');
        setContextName?.(collectionName === "My Private Collection" ? "**your private collection**" : "**" + collectionName + "**");
        setSources?.(selectedSources);
        if (context === "myContentsOnly" || context === "" || collectionType === "private" || collectionName === "My Private Collection") {
            setCollectionDefaultValue("private||My Private Collection|myContentsOnly");
        } else {
            setCollectionDefaultValue(`${collectionType}|${collectionProductLine}|${collectionName}|${context}`);
        }
        //eslint-disable-next-line
    }, [chatAgent, collection, collectionName, selectedSources, collectionProductLine, collectionType, context, collectionDefaultValue]);

    const resetChatWithDocStatus = () => {
        navigate({
            pathname: "/",
            search: queryParams.toString()
        });
        setSessionPath?.("");
        setNewChat?.(true);
        setPromptInput('');
        setIsChatActive?.(false);
        setChatSessionId?.(generateChatSessionId());
        setClearState?.(true);
        setActiveChatHistoryRecord(HISTORY_ZERO_STATE);
        setIsRestrictedChatSessionStatus?.("");
    }

    const resetCollectionSources = () => {
        resetChatWithDocStatus();
        setSources?.([]);
        setSearchTerm("");
        setSelectedSources?.([]);
        setIsSourcesOpen(false);
    }

    const getCollectionSources = async (a_collection, a_search_term, a_sort_order) => {
        (async () => {
            let response;
            try {
                setLoading(true);
                response = a_collection === "myContentsOnly" ? await getSources("true", "", a_search_term, a_sort_order) : await getSources("false", a_collection, a_search_term, a_sort_order);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                console.log("error fetching sources");
            }
            let list: SourceType[] = [];
            response.map((item) => {
                return list.push({ ...item, formattedDate: parse(item.ingested_date, 'dd-MMM-yyyy HH:mm', new Date()) });
            });
            setCollectionSources(list);
        })();
    };

    const onCollectionChange = (e) => {
        setCollectionType?.(e.target.value.split("|")[0]);
        setCollectionProductLine?.(e.target.value.split("|")[1]);
        setCollectionName?.(e.target.value.split("|")[2]);
        setCollection?.(e.target.value.split("|")[3]);
        resetCollectionSources();
    }

    const handleOpen = () => {
        getCollectionSources(collection, "", "desc");
        setIsSourcesOpen(true);
    };

    const handleClose = () => {
        setSearchTerm("");
        setIsSourcesOpen(false);
    };

    const handleDone = () => {
        handleClose();
        setShowDocPopup(false);
    };

    const handleSourceSelect = (e) => {
        resetChatWithDocStatus();
        if (e.target.checked) {
            setSelectedSources?.(selectedSources?.concat([e.target.value]));
            setSources?.(selectedSources?.concat([e.target.value]));
        } else {
            let index = (selectedSources?.indexOf(e.target.value, 0) === undefined) ? -1 : selectedSources?.indexOf(e.target.value, 0);
            if (index > -1) {
                let srcs = [...(selectedSources || [])];
                srcs?.splice(index, 1);
                setSelectedSources?.(srcs);
                setSources?.(srcs);
            }
        }
    };

    const selectAllSources = () => {
        resetChatWithDocStatus();
        setSources?.([]);
        setSelectedSources?.(filteredCollectionSources.map((item) => getFileName(item.source)));
    }

    const selectNoSources = () => {
        resetChatWithDocStatus();
        setSources?.([]);
        setSelectedSources?.([]);
    }

    const getFileName = (fullpath) => {
        return fullpath.split(/[/]+/).pop();
    }

    return (
        <div className="chat-menu-src-select" ref={docPopupRef} data-testid="chat-with-documents-popup">
            <ChatWithDocHeader setShowDocPopup={setShowDocPopup} />
            <div className="d-flex">
                <Form.Select
                    {...register("collection-list")}
                    aria-label="collection"
                    onChange={onCollectionChange}
                    defaultValue={collectionDefaultValue}
                    value={collectionDefaultValue}
                    data-testid="chat-with-collection-dropdown"
                    autoComplete="off"
                >
                    <option value="private||My Private Collection|myContentsOnly">My Private Collection</option>
                    {allowedCollections?.filter(collection => collection.perm === "filter").map(({ ckey, cline, ctype, cname }, index) => <option key={index} value={ctype + '|' + cline + '|' + cname + '|' + ckey} >{cname}</option>)}
                </Form.Select>
                <div>
                    <Button data-testid="arrow-forward-button" className="ms-2 mt-1 button-filter-src" onClick={() => handleOpen()} ><img src={icon_arrow} alt="Filter sources" title="Filter sources" /></Button>
                    {isSourcesOpen && (
                        <div className="chat-menu-src-popup">
                            <DocSourceListHeader
                                selectAllSources={selectAllSources}
                                selectNoSources={selectNoSources}
                                handleClose={handleClose}
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                            />
                            {
                                loading && (
                                    <div>
                                        <div className="loading text-center">
                                            <img src={icon_loading} alt="loading" />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                !loading && (
                                    <div className="chat-menu-src-list-wrapper">
                                        <DocSourceList
                                            collectionSources={filteredCollectionSources}
                                            getFileName={getFileName}
                                            selectedSources={selectedSources}
                                            handleSourceSelect={handleSourceSelect}
                                        />
                                        <div className="d-flex justify-content-between mt-3">
                                            <button data-testid="back-to-documents-popup-button" className="chat-menu-src-select-back" onClick={() => { handleClose() }} ><SourceBackIcon /><span className="ms-3">Back</span></button>
                                            <button data-testid="done-selecting-sources-button" className="chat-menu-src-select-done" onClick={() => { handleDone() }} >Done</button>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
