import { Row, Col } from "react-bootstrap";

import icon_up from "../../assets/icon-arrow-upwards.svg";
import icon_down from "../../assets/icon-arrow-downwards.svg";

import "./style.scss";

const ContentRowHeader = (props) => {
    const { sortCollectionSources, sortOrder } = props;

    return (
        <div key="-1" className="content-row-header">
            <Row>
                <Col lg={6}>
                    <div data-testid="name-of-document">Name</div>
                </Col>
                <Col lg={3}>
                    <div data-testid="date-of-document">
                        Date Added
                        <span>
                            <img
                                src={ sortOrder === "asc" ? icon_up : icon_down}
                                onClick={() => sortCollectionSources(sortOrder)}
                                alt={ sortOrder === "asc" ? "up" : "down"}
                                className="content-row-sort"
                            />
                        </span>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ContentRowHeader;
