import { useEffect, useState, useContext } from 'react';
import { Button } from "react-bootstrap";
import { ChatContext } from "../../context/ChatContext";
import { AppContext } from '../../context/AppContext';
import { ReactComponent as HistoryBackIcon } from "../../assets/icon-google-backwards.svg";
import { ReactComponent as NewChatIcon } from "../../assets/icon-topic.svg";
import "./style.scss";
import { ChatHistoryComponent } from "./ChatHistoryComponent";
import OverlayTooltip from './UI/OverlayTooltip';

const ChatHistorySidebar = (props) => {
    const { startNewTopicChat } = props;
    const { isPrivateChat } = useContext(ChatContext);
    const { isSideNavOpen, setIsSideNavOpen } = useContext(AppContext);
    const [pathSession_id, setPathSession_id] = useState("");
    const locationPath = window.location.pathname;
    let pathname = "";

    if (locationPath.indexOf("/session/") !== -1) {
        pathname = locationPath.substring(locationPath.lastIndexOf('/') + 1);
    }

    useEffect(() => {
        setPathSession_id(pathname);

        // close side navbar for smaller screens
        if (window.innerWidth < 960) {
            setIsSideNavOpen(false);
        }
        // eslint-disable-next-line  
    }, [pathname]);

    const handleNewChatClick = () => {
        if (window.innerWidth < 1260) {
            setIsSideNavOpen(false);
        }
        startNewTopicChat();
    };

    return (
        <>
            <div className={"menu-fixed-top" + (isSideNavOpen ? "" : " d-none")}>
                <Button data-testid="new-chat-button" className="new-chat-button fw-semibold" onClick={handleNewChatClick}>
                    <NewChatIcon />
                    <span>New Chat</span>
                </Button>
                <div className="chat-history-title-wrapper d-flex align-items-center justify-content-between">
                    <span className={"fw-semibold" + (isPrivateChat ? " private-chat-mode" : "")}>Chat History</span>
                    <OverlayTooltip
                        tooltipText="Close Panel"
                        buttonContent={<HistoryBackIcon />}
                        ariaLabel="back"
                        testId="back-button"
                        tooltipClassName="tooltip-sidebar"
                        buttonClassName="back-icon-button"
                        onClick={() => setIsSideNavOpen(!isSideNavOpen)}
                    />
                </div>
            </div>
            <div className={"menu-scrollable flex-grow-1 overflow-auto" + (isSideNavOpen ? "" : " d-none") + (isPrivateChat ? " private-chat-mode" : "")}>
                <ChatHistoryComponent pathSession_id={pathSession_id} setPathSession_id={setPathSession_id} />
            </div>
            <div className={"menu-fixed-bottom" + (isSideNavOpen ? "" : " d-none") + (isPrivateChat ? " private-chat-mode" : "")}>
                <div className="archive-msg" data-testid="archive-message-text">Archive your chats to keep beyond 30 days</div>
            </div>
        </>
    );
};

export default ChatHistorySidebar;
