import axios from "axios";
import { useState, useEffect, useRef, Fragment, useContext } from "react";
import { Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { InputGroup } from "react-bootstrap";
import { parse } from 'date-fns';
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import icon_tick from "../assets/icon-google-tick.svg";
import icon_loading from "../assets/elysia-loader.gif"
import icon_loading_stopped from "../assets/icon-loading-stopped.svg";
import icon_arrow from "../assets/icon-arrow-forward.svg";
import icon_reload from "../assets/icon-google-reload.svg";


import { PERSONALITY, CREATIVITY, ROLE, DOMAIN_EXPERTISE, WRITING_STYLE, SUPER_AGENT, MODELS, ELYSIA_INTERNAL_ERROR_MESSAGE } from "../config/settings";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faBroom } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChatMessage, { Message } from "../components/chat/ChatMessage";
import { ChatContext } from "../context/ChatContext";
import { getSources } from "../services/content";

import { PROMPT_WORD_LENGTH } from "../config/settings";
import "./style.scss";

let metadataSourceList = [];

const SandboxPage = ({ user }) => {
  const generateChatSessionId = () => { return "session" + new Date().getTime(); }

  const getTokenForModel = (modelName) => {
    for (const provider of MODELS) {
      const model = provider.models.find(m => m.name === modelName);
      if (model) {
        return model.tokens;
      }
    }
    return null; // Return null if the model name is not found
  }

  const { allowedCollections } = useContext(ChatContext);
  const { register, setValue } = useForm();
  const models = MODELS[0].models.map((model) => model.name);
  const [sbModelProvider, setSbModelProvider] = useState(MODELS[0].provider.toLowerCase());
  const [sbModelNameList, setSbModelNameList] = useState<string[]>(models);
  const [sbModelName, setSbModelName] = useState(models[0]);
  const [sbModelTokens, setSbModelTokens] = useState(getTokenForModel(models[0]));
  const [sbAgent, setSbAgent] = useState(SUPER_AGENT[0].id);
  const [sbSystemPrompt, setSbSystemPrompt] = useState(SUPER_AGENT[1].system_prompt);
  const [sbToolPrompt, setSbToolPrompt] = useState(SUPER_AGENT[0].tool_prompt);
  const [sbRagPrompt, setSbRagPrompt] = useState(SUPER_AGENT[0].rag_prompt);

  const [sbContext, setSbContext] = useState("");
  const [sbInlineContext, setSbInlineContext] = useState("");
  const [sbSources, setSbSources] = useState<string[]>([]);

  const [sbPrompt, setSbPrompt] = useState("");

  const [sbPersonality, setSbPersonality] = useState(PERSONALITY[0]);
  const [sbCreativity, setSbCreativity] = useState(CREATIVITY[0]);
  const [sbRole, setSbRole] = useState(ROLE[0]);
  const [sbDomainExpertise, setSbDomainExpertise] = useState(DOMAIN_EXPERTISE[0]);
  const [sbWritingStyle, setSbWritingStyle] = useState(WRITING_STYLE[0]);

  const chatBoxRef = useRef(document.createElement("textarea"));
  const [searchStatusMsg, setSearchStatusMsg] = useState(false);
  const [generateStatusMsg, setGenerateStatusMsg] = useState(false);
  const [chatMessages, setChatMessages] = useState<Message[]>([]);
  const [result, setResult] = useState<string | null>(null);
  const [chatSessioId, setChatSessionId] = useState(generateChatSessionId());

  const scrollRef = useRef<HTMLDivElement>(null);
  const resultScrollRef = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isProcessed, setProcessed] = useState(false);

  const [collection, setCollection] = useState("myContentsOnly");
  const [collectionType, setCollectionType] = useState("private");
  const [collectionProductLine, setCollectionProductLine] = useState("");
  const [collectionName, setCollectionName] = useState("My Private Collection");
  const [collectionSources, setCollectionSources] = useState<SourceType[]>([]);
  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSourcesOpen, setIsSourcesOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.location.pathname === '/sandbox') {
      const containerElt = Array.from(
        document.getElementsByClassName('main-container') as HTMLCollectionOf<HTMLElement>,
      );
      containerElt.forEach(box => {
        box.style.overflowY = 'hidden';
        box.style.marginTop = "0px";
      });
    }
  }, []);

  useEffect(() => {
    setValue("sb-model-provider", sbModelProvider);
    setValue("sb-model-name", sbModelName);
    setValue("sb-agent", sbAgent);
    setValue("sb-system-prompt", sbSystemPrompt);
    setValue("sb-tool-prompt", sbToolPrompt);
    setValue("sb-rag-prompt", sbRagPrompt);
    setValue("sb-personality", sbPersonality);
    setValue("sb-creativity", sbCreativity);
    setValue("sb-role", sbRole);
    setValue("sb-domain-expertise", sbDomainExpertise);
    setValue("sb-writing-style", sbWritingStyle);
    setValue("sb-inline-context", sbInlineContext);
    setSbModelTokens(getTokenForModel(sbModelName));
    // eslint-disable-next-line 
  }, [sbModelProvider, sbModelName, sbAgent, sbSystemPrompt, sbToolPrompt, sbRagPrompt, sbPersonality, sbCreativity, sbRole, sbDomainExpertise, sbWritingStyle, sbInlineContext]);

  useEffect(() => {
    if (chatMessages.length) {
      scrollRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    }
  }, [chatMessages.length]);

  useEffect(() => {
    if (sbPrompt && sbPrompt !== "" && !isProcessed) {
      if (sbPrompt !== "" && !sbPrompt.startsWith("auto-trigger-prompt")) {
        let newMessage: Message = {
          type: "User",
          body: sbPrompt
        };
        setChatMessages((prevArray) => [...prevArray, newMessage]);
      }
      processPrompt();
      setProcessed?.(true);
    }
    //eslint-disable-next-line
  }, [sbPrompt, isProcessed, setProcessed, setIsLoading]);

  useEffect(() => {
    setValue("collection-list", collectionType + "|" + collectionProductLine + "|" + collectionName + "|" + collection);
    setSbContext?.(collection);
    setSbSources?.(selectedSources);
    // eslint-disable-next-line
  }, [collection, collectionName, selectedSources, collectionProductLine, collectionType]);

  let appendMesgToChatHistory = (message, runId) => {
    let newMessage: Message = {
      type: "System",
      body: message,
      showInteractions: runId && runId !== "" ? true : false,
      prompts: [],
      runId: runId,
      references: metadataSourceList
    };
    setSearchStatusMsg(false);
    setGenerateStatusMsg(false);
    setResult("");
    setIsLoading(false);
    metadataSourceList = [];
    localStorage.setItem("message", "");
    localStorage.setItem("runId", "");
    setChatMessages((prevArray) => [...prevArray, newMessage]);
  }

  let processStreamingRequest = async () => {
    let url = getStreamingServiceUrl();
    let payload = getStreamingServicePayload();
    let auth = (axios.defaults.headers).common['Authorization'];
    metadataSourceList = [];
    try {
      var response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${auth}`,
        },
        body: JSON.stringify(payload),
      });
      var reader = response?.body?.getReader();
      var decoder = new TextDecoder("utf-8");

      reader?.read().then(function processResult(result) {
        if (result.done) {
          appendMesgToChatHistory(localStorage.getItem("message"), localStorage.getItem("runId"));
          return;
        }
        let token = decoder.decode(result.value);
        if (token !== "" && !token.includes("METADATA: ")) {
          localStorage.setItem(
            "message",
            (localStorage.getItem("message")
              ? localStorage.getItem("message")
              : "") + token
          );
          setResult(
            localStorage.getItem("message") ? localStorage.getItem("message") : ""
          );
          resultScrollRef.current?.scrollIntoView(true);
        }
        if (token.includes("METADATA: ")) {
          let remainingText = token
            .substring(0, token.indexOf("METADATA: "));
          localStorage.setItem(
            "message",
            (localStorage.getItem("message")
              ? localStorage.getItem("message")
              : "") + remainingText
          );
          let metadataText = token
            .substring(token.indexOf("METADATA: "));
          try {
            let metaObj = JSON.parse(metadataText.substring(10));
            metadataSourceList = metaObj.references;
            localStorage.setItem("runId", metaObj.run_id);
            setResult(
              localStorage.getItem("message") ? localStorage.getItem("message") : ""
            );
            resultScrollRef.current?.scrollIntoView(true);
          } catch (e) {
            console.log(e)
          }
        }
        return reader?.read().then(processResult);
      });
    } catch (e: any) {
      appendMesgToChatHistory(ELYSIA_INTERNAL_ERROR_MESSAGE, "");
      //catchError(e);
    }
  };

  let processPrompt = async () => {
    if (sbPrompt !== "") {
      setIsLoading(true);
      localStorage.setItem("message", "");
      setResult("");
      setSearchStatusMsg(true);
      setTimeout(() => {
        setGenerateStatusMsg(true);
      }, 4000);
      processStreamingRequest();

    } else {
      alert("Please enter a prompt...");
    }
  };

  const setAgentWithPrompt = (val) => {
    setSbAgent(val);
    const agent = SUPER_AGENT[SUPER_AGENT.findIndex(p => p.id === val)];
    let systemPrompt = '';
    let agentRagPrompt = '';
    let agentToolPrompt = '';

    switch (val) {
      case 'Generic':
        agentRagPrompt = agent?.rag_prompt ?? "";
        agentToolPrompt = agent?.tool_prompt ?? "";
        break;
      case 'Document':
        systemPrompt = agent?.system_prompt ?? "";
        break;
    }
    setSbToolPrompt(agentToolPrompt);
    setSbRagPrompt(agentRagPrompt);
    setSbSystemPrompt(systemPrompt);
  }

  const setSbModelProviderWithNames = (val) => {
    setSbModelProvider(val);
    const index = MODELS.findIndex(p => p.provider.toLowerCase() === val);
    const models = MODELS[index].models.map((model) => model.name);
    setSbModelNameList(models);
    setSbModelName(models[0]);
    setSbModelTokens(getTokenForModel(models[0]));
  }

  function wordCount(text = '') {
    return text.split(/\S+/).length - 1;
  };

  const handleSendClick = () => {
    if (chatBoxRef.current.value) {
      if (wordCount(chatBoxRef.current.value) < PROMPT_WORD_LENGTH) {
        if (sbAgent === "GenericWithContext" && wordCount(sbInlineContext) > 1000) {
          alert("Please enter less than 1000 words in Context box.");
          return;
        }
        setSbPrompt(chatBoxRef.current.value);
        setProcessed?.(false);
        chatBoxRef.current.value = "";
      } else {
        alert("Please enter your question in less than " + PROMPT_WORD_LENGTH + " words.");
      }
    } else {
      alert("Please enter your question in the box provided.")
    }
  }

  const handlePromptChange = (e: any) => {
    if (e.target.value) {
      if (wordCount(e.target.value) < PROMPT_WORD_LENGTH) {
        if (sbAgent === "GenericWithContext" && wordCount(sbInlineContext) > 1000) {
          alert("Please enter less than 1000 words in Context box.");
          return;
        }
        setSbPrompt(chatBoxRef.current.value);
        setProcessed?.(false);
        chatBoxRef.current.value = "";
      } else {
        alert("Please enter your question in less than " + PROMPT_WORD_LENGTH + " words.");
      }
    } else {
      alert("Please enter your question in the box provided.")
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handlePromptChange(e);
    }
  };

  const getStreamingServiceUrl = () => {
    return `${process.env.REACT_APP_API_DOMAIN}v1/ai/chat/stream/completion`;
  };


  const getStreamingServicePayload = () => {
    let valPrompt = sbPrompt ? sbPrompt : "";
    let payload = {
      appId: `${process.env.REACT_APP_ELYSIA_APP_ID}`,
      query: valPrompt,
      model: sbModelProvider,
      name_of_model: sbModelName,
      tokens: sbModelTokens,
      creativity: sbCreativity,
      personality: sbPersonality,
      role: sbRole,
      writing_style: sbWritingStyle,
      domain_expertise: sbDomainExpertise,
      input_language: "English",
      output_language: "English",
      chat_session: chatSessioId,
      system_prompt: sbSystemPrompt,
      agent_rag_prompt: sbRagPrompt,
      agent_tool_prompt: sbToolPrompt,
      concepts: [],
      entities: [],
      business_units: [],
      products: [],
      content_domains: [],
      showSourceList: false,
      include_search: false,
      include_metadata: true
    }

    if (sbAgent === "Document") {
      if (sbContext && sbContext !== "myContentsOnly") {
        payload["context"] = sbContext;
        payload["filterMyContentsOnly"] = false;
        if (sbSources && sbSources.length > 0) {
          payload["sources"] = sbSources;
        }
      }
      if (sbContext && sbContext === "myContentsOnly") {
        payload["filterMyContentsOnly"] = true;
        if (sbSources && sbSources.length > 0) {
          payload["sources"] = sbSources;
        }
      }
      payload.showSourceList = true;
    }

    return payload;
  };

  const cleanHistory = () => {
    setChatMessages([]);
    setChatSessionId(generateChatSessionId());
  }

  const truncate = (str, length) => {
    if (str.length > length) {
      return `${str.substring(0, length)}...`;
    }
    return str;
  };

  const getCollectionSources = async (a_collection, a_search_term, a_sort_order) => {
    (async () => {
      let response;
      try {
        setLoading(true);
        response = a_collection === "myContentsOnly" ? await getSources("true", "", a_search_term, a_sort_order) : await getSources("false", a_collection, a_search_term, a_sort_order);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log("error fetching sources");
      }
      let list: SourceType[] = [];
      response.map((item) => {
        return list.push({ ...item, formattedDate: parse(item.ingested_date, 'dd-MMM-yyyy HH:mm', new Date()) });
      });
      setCollectionSources(list);
    })();
  };

  const reloadCollectionSources = (a_collection, a_search_term, a_sort_order) => {
    getCollectionSources(a_collection, a_search_term, a_sort_order);
  }

  const resetCollectionSources = () => {
    setSbSources?.([]);
    setSearchTerm("");
    setSelectedSources([]);
    setIsSourcesOpen(false);
  }

  const onCollectionChange = (e) => {
    setCollectionType(e.target.value.split("|")[0]);
    setCollectionProductLine(e.target.value.split("|")[1]);
    setCollectionName(e.target.value.split("|")[2]);
    setCollection(e.target.value.split("|")[3]);
    resetCollectionSources();
  }

  const handleOpen = () => {
    getCollectionSources(collection, "", "desc");
    setIsSourcesOpen(true);
  };

  const handleClose = () => {
    setIsSourcesOpen(false);
  };

  const handleSourceSelect = (e) => {
    if (e.target.checked) {
      setSelectedSources(selectedSources?.concat([e.target.value]));
      setSbSources?.(selectedSources?.concat([e.target.value]));

    } else {
      let index = (selectedSources?.indexOf(e.target.value, 0) === undefined) ? -1 : selectedSources?.indexOf(e.target.value, 0);
      if (index > -1) {
        let srcs = [...selectedSources]
        srcs?.splice(index, 1);
        setSelectedSources(srcs);
        setSbSources?.(srcs);
      }
    }
  };

  const selectAllSources = () => {
    setSbSources?.([]);
    setSelectedSources(collectionSources.map((item) => getFileName(item.source)));
  }

  const selectNoSources = () => {
    setSbSources?.([]);
    setSelectedSources([]);
  }

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    reloadCollectionSources(collection, e.target.value, "desc");
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (e.target.value !== searchTerm)
        handleSearch(e)
    }
  };

  const handleBlur = (e: any) => {
    e.preventDefault();
    if (e.target.value !== searchTerm)
      handleSearch(e);
  };

  const getFileName = (fullpath) => {
    return fullpath.split(/[/]+/).pop();
  }

  return (
    <div className="sandboxContainer">
      <Col lg={3} xs={3} sm={3} md={3} xl={3} className="settingsColumn">
        <div className="setting-options-column" >
          <div>
            <div>Select Model Provider </div>
            <Form.Select
              aria-label="model provider"
              size="sm"
              {...register("sb-model-provider")}
              onChange={(e) => setSbModelProviderWithNames(e.target.value)}
            >
              {MODELS?.map((item, index) => (
                <option value={item.provider.toLowerCase()} key={index}>{item.provider}</option>
              ))}
            </Form.Select>
          </div>
        </div>

        <div className="setting-options-column">
          <div>
            <div>Select Model Name </div>
            <Form.Select
              size="sm"
              aria-label="model name"
              {...register("sb-model-name")}
              onChange={(e) => setSbModelName(e.target.value)}
            >
              {sbModelNameList?.map((item, index) => (
                <option value={item} key={index}>{item}</option>
              ))}
            </Form.Select>
          </div>
        </div>
        <div className="setting-options-column">
          <div>
            <div>Select Agent </div>
            <Form.Select
              size="sm"
              aria-label="agent"
              {...register("sb-agent")}
              onChange={(e) => setAgentWithPrompt(e.target.value)}
            >
              {SUPER_AGENT?.map((item, index) => (
                <option value={item.id} key={index}>{item.name}</option>
              ))}
            </Form.Select>
          </div>
        </div>
        <div style={{ padding: "8px 8px 8px 0px", display: sbAgent === "GenericWithContext" ? "block" : "none" }}>
          <div>
            <div>Enter Context</div>
            <Form.Control
              {...register("sb-inline-context")}
              className="shadow-sm bg-white rounded"
              name="sb-inline-context"
              as="textarea"
              aria-label="context"
              rows={6}
              onChange={(e) => setSbInlineContext(e.target.value)}
            />
          </div>
        </div>
        <div style={{ padding: "8px 8px 8px 0px", display: sbAgent === "Document" ? "block" : "none" }} >
          <div>
            <div>Select Data Sources </div>
            <div style={{ display: "flex" }}>
              <div>
                <Form.Select
                  style={{ fontSize: "14px", width: "max-content" }}
                  {...register("collection-list")}
                  onChange={onCollectionChange}
                  autoComplete="off"
                >
                  <option value="private||My Private Collection|myContentsOnly">My Private Collection</option>
                  {allowedCollections?.filter(collection => collection.perm === "filter").map(({ ckey, cline, ctype, cname }, index) => <option key={index} value={ctype + '|' + cline + '|' + cname + '|' + ckey} >{cname}</option>)}
                </Form.Select>
              </div>
              <div style={{ padding: "8px 0px 8px 8px" }}>
                <Popup
                  trigger={
                    <img key={Math.random()} src={icon_arrow} alt="Filter sources" title="Filter sources" />
                  }
                  position="right top"
                  contentStyle={{
                    width: "auto",
                    maxWidth: `${Math.round(window.innerWidth) - 300}px`,
                    maxHeight: `${Math.round(window.innerHeight) - 500}px`,
                    zIndex: 1000
                  }}
                  open={isSourcesOpen}
                  onOpen={() => handleOpen()}
                  arrow={false}
                >
                  <div>
                    <div style={{ display: "flex" }}>
                      <div style={{ padding: "5px" }} className="chat-menu-src-select-link" ><img src={icon_reload} onClick={() => reloadCollectionSources(collection, searchTerm, "desc")} alt="reload" title="Refresh" style={{ width: "16px", height: "16px" }} /></div>
                      <div style={{ padding: "5px 12px" }} className="chat-menu-src-select-link" onClick={() => selectAllSources()}>Select All</div>
                      <div style={{ padding: "5px" }} className="chat-menu-src-select-link" onClick={() => selectNoSources()}>Select None</div>
                    </div>
                    <div style={{ margin: "10px" }}>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Search sources.."
                          aria-label="search sources"
                          onKeyDown={handleSearchKeyDown}
                          onBlur={handleBlur}
                        />
                      </InputGroup>
                    </div>
                    {loading && (
                      <div style={{ clear: "both" }}>
                        <div className="loading">
                          <img src={icon_loading} alt="loading" />
                        </div>
                      </div>
                    )}
                    {!loading && (
                      <>
                        <div style={{ clear: "both", maxHeight: `${Math.round(window.innerHeight) - 650}px`, overflowY: "auto", overflowX: "hidden" }}>
                          {collectionSources.map((item, index) => (
                            <div key={index} style={{ padding: "5px 5px" }}>
                              <label className="switch">
                                <input
                                  value={getFileName(item.source)}
                                  checked={selectedSources?.indexOf(getFileName(item.source)) > -1}
                                  type="checkbox"
                                  onChange={(e) => handleSourceSelect(e)}
                                />
                                <span className="slider round"></span>
                              </label>
                              <span title={item.title} style={{ paddingLeft: "10px" }}>
                                {truncate(item.title, 120)}
                              </span>
                            </div>
                          ))}
                        </div>
                        <div style={{ clear: "both", padding: "10px", float: "right" }}>
                          <button className="chat-menu-src-select-done" onClick={handleClose} >Done</button>
                        </div>
                      </>
                    )}
                  </div>
                </Popup>
              </div>
            </div>
          </div>
        </div>
        <div className="setting-options-column">
          <div>
            {sbAgent === 'Document' && (
              <>
                <div>Enter System Prompt</div>
                <Form.Control
                  {...register("sb-system-prompt")}
                  className="shadow-sm bg-white rounded"
                  name="sb-system-prompt"
                  aria-label="system prompt"
                  as="textarea"
                  rows={6}
                  onChange={(e) => setSbSystemPrompt(e.target.value)}
                />
              </>
            )}
            {sbAgent === 'Generic' && (
              <>
                <div>Enter Agent Tool Prompt</div>
                <Form.Control
                  {...register("sb-tool-prompt")}
                  className="shadow-sm bg-white rounded"
                  name="sb-tool-prompt"
                  as="textarea"
                  aria-label="agent tool prompt"
                  rows={6}
                  onChange={(e) => setSbToolPrompt(e.target.value)}
                />
                <div>Enter Agent RAG Prompt</div>
                <Form.Control
                  {...register("sb-rag-prompt")}
                  className="shadow-sm bg-white rounded"
                  name="sb-rag-prompt"
                  as="textarea"
                  aria-label="agent rag prompt"
                  rows={6}
                  onChange={(e) => setSbRagPrompt(e.target.value)}
                />
              </>
            )}
          </div>
        </div>
        <hr style={{ margin: "1rem .75rem 1rem 0" }} />
        <div className="setting-options-column">
          <div>
            <div>Select Personality </div>
            <Form.Select
              aria-label="personality"
              size="sm"
              {...register("sb-personality")}
              onChange={(e) => setSbPersonality(e.target.value)}
            >
              {PERSONALITY?.map((item, index) => (
                <option value={item} key={index}>{item}</option>
              ))}
            </Form.Select>
          </div>
        </div>
        <div className="setting-options-column">
          <div>
            <div>Select Creativity </div>
            <Form.Select
              size="sm"
              aria-label="creativity"
              {...register("sb-creativity")}
              onChange={(e) => setSbCreativity(e.target.value)}
            >
              {CREATIVITY?.map((item, index) => (
                <option value={item} key={index}>{item}</option>
              ))}
            </Form.Select>
          </div>
        </div>
        <div className="setting-options-column">
          <div>
            <div>Select Role </div>
            <Form.Select
              size="sm"
              aria-label="role"
              {...register("sb-role")}
              onChange={(e) => setSbRole(e.target.value)}
            >
              {ROLE?.map((item, index) => (
                <option value={item} key={index}>{item}</option>
              ))}
            </Form.Select>
          </div>
        </div>
        <div className="setting-options-column">
          <div>
            <div>Select Domain of Expertise </div>
            <Form.Select
              size="sm"
              aria-label="domain of expertise"
              {...register("sb-domain-expertise")}
              onChange={(e) => setSbDomainExpertise(e.target.value)}
            >
              {DOMAIN_EXPERTISE?.map((item, index) => (
                <option value={item} key={index}>{item}</option>
              ))}
            </Form.Select>
          </div>
        </div>
        <div className="setting-options-column">
          <div>
            <div>Select Writing Style </div>
            <Form.Select
              size="sm"
              aria-label="writing style"
              {...register("sb-writing-style")}
              onChange={(e) => setSbWritingStyle(e.target.value)}
            >
              {WRITING_STYLE?.map((item, index) => (
                <option value={item} key={index}>{item}</option>
              ))}
            </Form.Select>
          </div>
        </div>
      </Col>
      <Col lg={9} xs={9} sm={9} md={9} xl={9} className="sandboxChatContainer">
        <div className="chatboxcontainer">
          {chatMessages && chatMessages.length > 0 && (
            <div>
              {chatMessages.map((chatMsg, index) => (
                <Fragment key={index + 100}>
                  <ChatMessage key={index} message={chatMsg} />
                </Fragment>
              ))}
              {isLoading && result === "" && searchStatusMsg && (
                <div className="generating-results-status">
                  {" "}
                  <img src={icon_tick} alt="done" /> Generating results
                </div>
              )}
              {isLoading && result === "" && generateStatusMsg && (
                <div className="generating-results-status">
                  {" "}
                  <img src={icon_tick} alt="done" /> Generating answers
                </div>
              )}
              {result !== "" && (
                <div>
                  <ChatMessage
                    message={{
                      type: "System",
                      body: result,
                    }}
                  />
                  <div ref={resultScrollRef} />
                </div>
              )}
              <div ref={scrollRef} />
            </div>
          )}
        </div>
        <div className="chat-input">
          {isLoading && (
            <div className="chat-input-loading"><img src={icon_loading} alt="loading" /></div>
          )}
          {!isLoading && (
            <div className="chat-input-loading"><img src={icon_loading_stopped} alt="not_loading" /></div>
          )}
          <InputGroup className="mb-3">
            <button style={{ marginTop: "18px", marginRight: "10px" }} onClick={cleanHistory}>
              <FontAwesomeIcon icon={faBroom} /> &nbsp; Clean
            </button>
            <Form.Control {...register('chat-input')}
              className="shadow-sm bg-white rounded input-for-sandbox"
              name="chat-input"
              as="textarea"
              aria-label="chat input"
              ref={chatBoxRef}
              rows={3}
              onKeyDown={handleKeyDown}
            />
            <FontAwesomeIcon icon={faPaperPlane} className="chat-icon" title="Send" onClick={handleSendClick} />
          </InputGroup>
          <div style={{ marginLeft: "100px" }} className="chat-input-protection-msg">Your personal and company data are protected in this chat.</div>
        </div>
      </Col>
    </div>
  );
};

export default SandboxPage;
