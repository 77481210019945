
import { useEffect, useState } from "react";
import icon_elysia_brain from "../../assets/icon-elysia-brain.svg";

import { getSkills, getInterests } from "../../services/profile";
import notify from "../../services/notify";
import { Button } from "react-bootstrap";
import { ReactComponent as IconEdit } from "../../assets/icon-edit.svg";
import { ReactComponent as IconClose } from "../../assets/icon-google-close.svg"
import MultiSelectComponent from "../common/MultiSelect";

interface SkillAndInterestsProps {
  profileInfo: any;
  updateProfileInfo: any;
  pageState: string;
  page: 'skills' | 'interests';
}

export const SkillAndInterests = ({
  profileInfo,
  updateProfileInfo,
  pageState,
  page,
}: SkillAndInterestsProps) => {

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [didMount, setDidMount] = useState(false);

  const [options, setOptions] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [placeholderText, setPlaceholderText] = useState<string>('Type to add');
  const [disclaimerText, setDisclaimerText] = useState<string>('');

  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  useEffect(() => {
    if (didMount) {
      setSelectedOptions(profileInfo?.[page]);
    }
    // eslint-disable-next-line
  }, [profileInfo, didMount]);

  useEffect(() => {
    if (profileInfo?.[page].length) {
      setDisclaimerText('This content was generated by Elysia based on your profile');
    } else {
      setDisclaimerText('Please connect a social network to generate your profile or alternatively manually populate');
    }
    // eslint-disable-next-line
  }, [profileInfo]);

  useEffect(() => {
    page === 'skills' ? setPlaceholderText('Type a skill to add') :
      setPlaceholderText('Type an interest to add')
  }, [page]);

  if (!didMount) {
    return null;
  }

  const getAllOptions = () => {
    (async () => {
      let response;
      setLoading(true);
      try {
        if (page === 'skills') {
          response = await getSkills();
        } else {
          response = await getInterests();
        }
      } catch (err) {
        setLoading(false);
        console.error(`error fetching ${page}`);
      }
      setOptions(response);
      setLoading(false);
    })();
  }

  const enableEditMode = () => {
    getAllOptions();
    setIsEditMode(true);
  }

  const resetSelections = () => {
    setSelectedOptions(profileInfo?.[page]);
    setIsEditMode(false);
  }

  const saveSelection = async () => {
    let newProfileInfo = { ...profileInfo }
    newProfileInfo[page] = selectedOptions;
    const response = await updateProfileInfo(newProfileInfo);
    if (response) {
      notify({
        message: `Your Elysia profile has been successfully updated.`,
        type: 'success'
      });
      setIsEditMode(false);
    }
  }

  const removeSelection = (option) => {
    setSelectedOptions(selectedOptions.filter((item) => item !== option));
  }

  const createOption = (option) => {
    setSelectedOptions([...selectedOptions, option]);
  }

  return (
    <>
      {pageState === "all" && (
        <div className="menu-option-box p-4 mb-4">
          <div className="d-flex justify-content-between align-items-baseline">
            <p data-testid={`${page}-title`} className="text-capitalize mb-0">{page}</p>
            {!isEditMode && <Button className="edit" aria-label={`Edit ${page}`} data-testid={`edit-${page}`} onClick={enableEditMode}>
              <IconEdit />
            </Button>
            }
          </div>
          <div data-testid={`${page}-disclaimer`} className="d-flex align-items-center system-default-message">
            <img src={icon_elysia_brain} alt="Elysia" /> {disclaimerText}
          </div>
          {isEditMode && <MultiSelectComponent
            loading={loading}
            options={options}
            page={page}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            onAddOptions={saveSelection}
            onCreateOption={createOption}
            placeholderText={placeholderText}
          />}
          {selectedOptions?.length > 0 &&
            <div className="badge-container">
              {selectedOptions?.map((option, index) => {
                return (
                  <span key={index} className={`badge ${isEditMode ? "edit" : ""}`}>
                    {option}
                    {isEditMode && <Button aria-label={`Remove ${page}`} data-testid={`remove-${page}`} onClick={() => {
                      removeSelection(option);
                    }}>
                      <IconClose />
                    </Button>}
                  </span>
                );
              })}
            </div>
          }
          {isEditMode && <div className="button-container d-flex justify-content-between">
            <Button data-testid={`cancel-edit-${page}`} className="light-without-border" onClick={resetSelections}>
              Cancel
            </Button>
            <Button vdata-testid={`save-${page}`} variant="primary" className="light-with-border" onClick={saveSelection}>Save</Button>
          </div>}
        </div>
      )}
    </>
  );
};
