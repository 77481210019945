const awsconfig = {
    aws_project_region: process.env.REACT_APP_COGNITO_REGION,
    aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
    aws_user_pools_id: process.env.REACT_APP_COGNITO_USERPOOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_WEBCLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: ['email', 'openid', 'iris.apis/ai'],
      redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT,
      responseType: 'code',
      auth0_identity_provider: 'Azure-SSO',
    },
};
  
export default awsconfig;
  