import React from "react";
import { Amplify } from 'aws-amplify';

import { AppRoutes } from './routes';
import awsconfig from "./config/aws";

const App = () => {
  Amplify.configure(awsconfig);  

  return (   
      <AppRoutes /> 
  );
};

export default App;
