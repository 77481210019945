import axios from "axios";
import { catchError } from "./common";


export const getAppList = async (): Promise<AppList> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}v1/ai/apps/list`
    );
    return response.data.data;
  } catch (e: any) {
    console.log(e);
    catchError(e);
    //return not used, keep typescript happy
    let retObj: any;       
    return retObj;
  }
};


export const getAppById = async (appId: string): Promise<AppType> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}v1/ai/apps/info?id=${appId}`
    );
    return response.data.data;
  } catch (e: any) {
    console.log(e);
    catchError(e);
    //return not used, keep typescript happy
    let retObj: any;       
    return retObj;
  }
};
