import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import './style.scss';
import notify from "../../services/notify";
import { isValidLinkedinUrl } from "../../utils/url";
import { updateLinkedIn } from "../../services/profile";

import ProfileDetailsView from "./ProfileDetailsView";
import SocialNetworks from "./SocialNetworks";
import OptInSettings from "./OptInSettings";
import UpdateProfileModal from "./UpdateProfileModal";

interface ProfileDetailsProps {
  profileInfo: any;
  updateProfileInfo: (info: any) => Promise<any>;
  pageState: string;
  setPageState: (state: string) => void;
}

export const ProfileDetails = ({ profileInfo, updateProfileInfo, pageState, setPageState }: ProfileDetailsProps) => {
  const [linkedinUrl, setLinkedinUrl] = useState(profileInfo?.linkedin);
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [showUpdateProfileModal, setShowUpdateProfileModal] = useState(false);

  const hideProfileUpdateModal = () => setShowUpdateProfileModal(false);

  const { setValue } = useForm();

  useEffect(() => {
    setValue("linkedin-url", profileInfo?.linkedin);
    setLinkedinUrl(profileInfo?.linkedin);
    setIsValidUrl(isValidLinkedinUrl(profileInfo?.linkedin ? profileInfo?.linkedin : ""));
  }, [profileInfo, setValue]);

  const processUpdateProfile = () => {
    setIsValidUrl(isValidLinkedinUrl(linkedinUrl));
    setShowUpdateProfileModal(true);
  };

  const setOptInSetting = async (val: string) => {
    let newProfileInfo = { ...profileInfo };
    newProfileInfo.optOut = val === "Yes" ? false : true;
    const response = await updateProfileInfo(newProfileInfo);
    if (response) {
      notify({
        message: 'Your Elysia configuration has been successfully updated.',
        type: 'success'
      });
      setPageState('all');
    }
  };

  const regenerateProfile = async () => {
    if (isValidLinkedinUrl(linkedinUrl)) {
      const response = await updateLinkedIn(profileInfo?.fullName, linkedinUrl);
      if (response) {
        notify({
          message: 'Your profile is being uploaded. You will receive an email when completed',
          type: 'success'
        });
        setShowUpdateProfileModal(false);
        setPageState('all');
      } else {
        notify({
          message: 'Profile update failed',
          type: 'error'
        });
        setShowUpdateProfileModal(false);
      }
    }
  };

  return (
    <>
      {pageState === "all" && <ProfileDetailsView profileInfo={profileInfo} setPageState={setPageState} />}
      {pageState === "social" && <SocialNetworks profileInfo={profileInfo} setPageState={setPageState} processUpdateProfile={processUpdateProfile} linkedinUrl={linkedinUrl} setLinkedinUrl={setLinkedinUrl}/>}
      {pageState === "optIn" && <OptInSettings profileInfo={profileInfo} setPageState={setPageState} setOptInSetting={setOptInSetting} />}
      <UpdateProfileModal
        show={showUpdateProfileModal}
        hideProfileUpdateModal={hideProfileUpdateModal}
        isValidUrl={isValidUrl}
        regenerateProfile={regenerateProfile}
      />
    </>
  );
};

export default ProfileDetails;
