import React, { useState } from 'react';
import { useAccordionButton } from 'react-bootstrap';

interface CustomToggleProps {
    children: React.ReactNode;
    eventKey: string;
    disabled?: boolean;
    testId: string;
    id?: string;
    isOpen?: boolean;
}

export const CustomAccordionHeader = ({ children, eventKey, disabled = false, testId, id, isOpen = false }: CustomToggleProps) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
        setIsExpanded(!isExpanded);
    });
    const [isExpanded, setIsExpanded] = useState(isOpen);
    return (

        <h2 data-testid={testId} className="custom-accordion-header accordion-header" id={id}>
            <button type="button" disabled={disabled} onClick={decoratedOnClick} aria-expanded={isExpanded} className={`accordion-button ${isExpanded? '': 'collapsed'}`}>
                {children}
            </button>
        </h2>
    );
}