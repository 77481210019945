import { useContext, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { PromptContext } from "../../context/PromptContext";
import { ChatContext } from "../../context/ChatContext";
import { deleteSavedPrompts } from "../../services/savedPrompts";
import { generateChatSessionId } from "../../utils/chat";
import { HISTORY_ZERO_STATE } from "../../constants/HistoryConstants";

import { Button } from "react-bootstrap";
import { ReactComponent as SaveTopicIcon } from "../../assets/icon-google-topic.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icon-google-delete.svg";
import { ReactComponent as TickIcon } from "../../assets/icon-google-tick.svg";
import { LoadingContext } from "../../context/LoadingContext";
import OverlayTooltip from "../common/UI/OverlayTooltip";


export const SavedPromptButton = (props) => {
    const { topic, index, setSavedTopics, handleClose } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [deletedPromptId, setDeletedPromptId] = useState<number | null>(null);
    const [showBadge, setShowBadge] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const { isLoading } = useContext(LoadingContext);
    const { setPrompt, setProcessed } = useContext(PromptContext);
    const { setPromptInput, setNewChat, setChatSessionId, setClearState, setChatAgent, setActiveChatHistoryRecord, setSessionPath, setIsRestrictedChatSessionStatus, setIsStreamingRequested } = useContext(ChatContext);

    const startNewSavedPromptChat = (promptValue) => {
        localStorage.setItem("stopStreaming", "false");
        setIsStreamingRequested?.(true);
        setClearState?.(true);
        setNewChat?.(true);
        setChatSessionId?.(generateChatSessionId());
        setChatAgent?.("Generic");
        setActiveChatHistoryRecord(HISTORY_ZERO_STATE);
        navigate({
            pathname: "/",
            search: queryParams.toString()
        });
        setPromptInput("");
        setSessionPath?.("");
        setPrompt?.(promptValue);
        setProcessed?.(false);
        setIsRestrictedChatSessionStatus?.("");
        handleClose();
    };

    const handleDeleteSavedPrompts = async (promptId: number) => {
        try {
            const response = await deleteSavedPrompts(promptId);
            if (response) {
                setDeletedPromptId(promptId);
                setShowBadge(true);
                if (timeoutRef.current) clearTimeout(timeoutRef.current);
                timeoutRef.current = setTimeout(() => {
                    setDeletedPromptId(null);
                    setShowBadge(false);
                    setSavedTopics(prevTopics =>
                        prevTopics.filter(topic => topic.prompt_id !== promptId)
                    );
                }, 2000);
            }
        } catch (err) {
            console.error("error while deleting the saved prompt");
        }
    };

    return (
        <>
            <Button disabled={isLoading} className="saved-prompt-topic" key={index} onClick={() => { startNewSavedPromptChat(topic.prompt) }}>
                <div className="d-flex mb-2 saved-prompt-text-container">
                    <SaveTopicIcon className="save-topic-icon" />
                    <div className="ms-2 saved-topic-text">
                        {topic?.prompt}
                    </div>
                </div>
                <div className="delete-saved-topic">
                    <OverlayTooltip
                        tooltipText="Delete"
                        buttonContent={<DeleteIcon />}
                        ariaLabel="delete saved prompt"
                        testId="delete-saved-prompt-button"
                        tooltipClassName="save-prompt-tooltip"
                        buttonClassName="delete-saved-topic-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteSavedPrompts(topic.prompt_id);
                        }}
                        placement="bottom"
                        disabled={deletedPromptId === topic.prompt_id}
                    />
                </div>
                {showBadge && (
                    <span className="history-badge-delete-prompt-button">
                        <TickIcon />
                        <span>Deleted</span>
                    </span>
                )}
            </Button>

        </>
    );
};
