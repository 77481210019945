import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Sketch } from "@uiw/react-color";
import { InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import Accordion from 'react-bootstrap/Accordion';
import { Typeahead } from "react-bootstrap-typeahead";
import { getAppList } from "../services/apps";
import { getSources } from "../services/content";

const ChatWidgetGenerator = ({ user }) => {
  const [bgColor, setBgColor] = useState("#11A7D9");
  const [fgColor, setFgColor] = useState("#FFFFFF");
  const [title, setTitle] = useState("Informa Content Explorer");
  // const [width, setWidth] = useState("400px");
  // const [height, setHeight] = useState("800px");
  const [deploymentMode, setDeploymentMode] = useState("2")
  const [context, setContext] = useState("");
  const [appId, setAppId] = useState("")
  const [sourcesDoc, setSouresDoc] = useState("");
  const [firstSourceTitle, setFirstSoureTitle] = useState("");
  const [firstSourceImageUrl, setFirstSourcImageUrl] = useState(
    ""
  );
  const [firstSourceSubtitle, setFirstSourceSubTitle] = useState("");
  const [firstSourceCTATitle, setFirstSourceCTATitle] = useState("");
  const [firstSouceCTAUrl, setFirstSourceCTAUrl] = useState("");
  
  //eslint-disable-next-line
  const [chatWidgetJSCode, setWidgetJSCode] = useState(`<script defer="defer" src="${process.env.REACT_APP_CHAT_WIDGET_PATH}elysia-chatbot.js"></script>`);
  //eslint-disable-next-line
  const [chatWidgetCSSCode, setWidgetCSSCode] = useState(`<link href="${process.env.REACT_APP_CHAT_WIDGET_PATH}elysia-chatbot.css" rel="stylesheet">`);
  //eslint-disable-next-line
  const [chatWidgetHTMLCode, setWidgetHTMLCode] = useState('');
 
  //eslint-disable-next-line  
  const [chatWidgetTriggerCode, setWidgetTriggerCode] = useState(' ' +
            '<script>\n' + 
            'function openElysiaChat() {\n' +
            '  document.getElementById("elysiaOpenChatBtn").click();\n' +
            '} \n' +
            'function closeElysiaChat() {\n' +
            '  document.getElementById("elysiaCloseChatBtn").click();\n' +
            '}\n' +
            'function elysiaChatOpenCallback() {\n' +     
            ' // callback handler called after widget is opened \n' +      
            '}\n' +
            'function elysiaChatCloseCallback() {\n' +     
            ' // callback handler called after widget is closed \n' +           
            '}\n' +
            '</script>\n' 
            ); 

  const [appList, setAppList] = useState([])      
  const [sources, setSources] = useState([])   

  const { register, setValue } = useForm();

  useEffect(()=>{
    let response; 
    (async () => {
      try {
        response = await getAppList();
      } catch (err) {
        console.log("error fetching apps");
      }
      setAppList(response);
    })(); 
  }, []);

  useEffect(()=>{
    if (context !== "") {
      let response; 
      (async () => {
        try {
          response = await getSources("false", context);
        } catch (err) {
          console.log("error fetching sources");
        }
        let list = []
        response.map((item) => {
          return list.push(item.source)
        })
        setSources(list);
      })(); 
    }    
  }, [context]);
  
  useEffect(() => { 
    setValue("widget-title", title);
    // setValue("widget-width", width);
    // setValue("widget-height", height);
    setValue("deployment-mode", deploymentMode);
    setValue("sources-context", context);
    setValue("sources-doc", sourcesDoc);
    setValue("first-source-title", firstSourceTitle);
    setValue("first-source-image-url", firstSourceImageUrl);
    setValue("first-source-sub-title", firstSourceSubtitle);
    setValue("first-source-cta-title", firstSourceCTATitle);
    setValue("first-source-cta-url", firstSouceCTAUrl);    
    setValue("widget-css", chatWidgetCSSCode);
    setValue("widget-js", chatWidgetJSCode);
    setValue("widget-trigger", chatWidgetTriggerCode);
 
    //html code
    let htmlCode = '';
    htmlCode += '<div \n' ;
    htmlCode += '  id="elysia-chatbot" \n' ;
    htmlCode += `  elysiaAppId="${appId}" \n` ;
    htmlCode += `  elysiaTitle="${title}" \n` ;
    htmlCode += `  elysiaContext="${context}" \n` ;
    // htmlCode += `  elysiaWidth="${width}" \n` ;
    // htmlCode += `  elysiaHeight="${height}" \n` ;   
    htmlCode += `  elysiaBgColor="${bgColor}" \n` ;
    htmlCode += `  elysiaFgColor="${fgColor}" \n` ;    
    if(sourcesDoc !== '') htmlCode += `  elysiaSourceDocs="${sourcesDoc}" \n` ;
    if(firstSourceTitle !== '') htmlCode += `  elysiaFirstSourceTitle="${firstSourceTitle}" \n` ;
    if(firstSourceImageUrl !== '') htmlCode += `  elysiaFirstSourceImageUrl="${firstSourceImageUrl}" \n` ;
    if(firstSourceSubtitle !== '') htmlCode += `  elysiaFirstSourceSubtitle="${firstSourceSubtitle}" \n` ;
    if(firstSourceCTATitle !== '') htmlCode += `  elysiaFirstSourceCTATitle="${firstSourceCTATitle}" \n` ;
    if(firstSouceCTAUrl !== '') htmlCode += `  elysiaFirstSourceCTAUrl="${firstSouceCTAUrl}" \n` ;
    if(deploymentMode === "1") htmlCode += `  elysiaHash="${user.signInUserSession.accessToken.jwtToken}" \n` ;
    htmlCode += '></div> \n' ;
    setValue("widget-html", htmlCode);
    //eslint-disable-next-line
  }, [    
    bgColor,
    fgColor,
    title,
    // width,
    // height,
    appId,
    context,
    deploymentMode,
    sourcesDoc,
    firstSourceTitle,
    firstSourceImageUrl,
    firstSourceSubtitle,
    firstSourceCTATitle,
    firstSouceCTAUrl,
  ]);

  const handleColorChangeComplete = (color, type) => {
    if (type === "fg") setFgColor(color.hex);
    else setBgColor(color.hex);
  }; 

  const setAppAttributes = (val) => {
    setAppId( val.split("||")[0])
    setContext(val.split("||")[1])
  }
  return (
    <>
      <Row>
        <Col lg="5">
          <div>
            <div className="title1 color-primary">Chat Widget Generator</div>
            <div className="gap">&nbsp;</div>
            <div className="p4">
              Use the form below to generate the code needed to enable Elysia chat on your web site-
            </div>
            <div className="gap">&nbsp;</div>
            <div className="settings-box">
              <div className="p4">
                <div className="settings-row">
                  <div>
                  <div>Select Widget Title <span className="mandatory">*</span> </div>    
                    <Form.Control
                      {...register("widget-title")}
                      type="text"
                      placeholder="" 
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                </div>
                <div className="settings-row">
                  <div>
                    <div>Select Theme Background <span className="mandatory">*</span></div>
                    <Sketch
                      color={bgColor}
                      onChange={(color) => {
                        handleColorChangeComplete(color, "bg");
                      }}
                    />
                  </div>
                  <div>
                    <div> Select Theme Foreground <span className="mandatory">*</span> </div>
                    <Sketch
                      color={fgColor}
                      onChange={(color) => {
                        handleColorChangeComplete(color, "fg");
                      }}
                    />
                  </div>
                </div>
                {/* <div className="settings-row">
                  <div>
                   <div>Select Widget Width <span className="mandatory">*</span> </div>
                    <Form.Control
                      {...register("widget-width")}
                      type="text"
                      placeholder=""
                      onChange={(e) => setWidth(e.target.value)}
                    />
                  </div>
                  <div>
                    <div>Select Widget Height <span className="mandatory">*</span> </div>
                    <Form.Control
                      {...register("widget-height")}
                      type="text"
                      placeholder=""
                      onChange={(e) => setHeight(e.target.value)}
                    />
                  </div>
                </div> */}
              </div>
              <div className="settings-row" style={{ flexDirection: "column" }}>                     
                  <div>Select Widget Deployment Mode  </div>
                  <Form.Select
                    {...register("deployment-mode")}
                    onChange={(e) => setDeploymentMode(e.target.value)}
                  >                          
                    <option value="1">Standalone</option>
                    <option value="2">Embeded</option>
                  </Form.Select>                                                  
              </div>
              <div className="settings-row">   
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Select Sources</Accordion.Header>
                    <Accordion.Body>
                      <div className="settings-row">
                        <div style={{ flexDirection: "column" }}>
                          <div>Select App Context <span className="mandatory">*</span> </div>
                          <Form.Select
                            {...register("source-context")}                            
                            onChange={(e) => setAppAttributes(e.target.value)}
                          > 
                          <option value="0" >Select Here</option>
                          {
                            appList.map((item) => (
                              <option value={item.id+"||"+item.context} key={item.id}>{item.name}</option>
                            ))
                          }   
                          </Form.Select>

                          Select Sources (File Names) -
                                               
                          <Typeahead
                            id="basic-typeahead-multiple"
                            labelKey="source"
                            multiple
                            onChange={setSouresDoc}
                            options={sources}
                            placeholder="Choose sources..."
                            selected={sourcesDoc}
                            style={{lineHeight:"20px"}}
                          />
                         

                          Input First Source Document Title*** 
                          <Form.Control
                            {...register("first-source-title")}
                            name="first-source-title"
                            type="text"
                            placeholder=""
                            onChange={(e) => setFirstSoureTitle(e.target.value)}
                          />
                          Input First Source Image URL*** -
                          <Form.Control
                            {...register("first-source-image-url")}
                            name="first-source-image-url"
                            type="text"
                            placeholder=""
                            onChange={(e) => setFirstSourcImageUrl(e.target.value)}
                          />
                          Input First Source Sub Title*** -
                          <Form.Control
                            {...register("first-source-sub-title")}
                            name="first-source-sub-title"
                            type="text"
                            placeholder=""
                            onChange={(e) => setFirstSourceSubTitle(e.target.value)}
                          />
                          Input First Source CTA Title*** -
                          <Form.Control
                            {...register("first-source-cta-title")}
                            name="first-source-cta-title"
                            type="text"
                            placeholder=""
                            onChange={(e) => setFirstSourceCTATitle(e.target.value)}
                          />
                          Input First Source CTA URL*** -
                          <Form.Control
                            {...register("first-source-cta-url")}
                            name="first-source-cta-url"
                            type="text"
                            placeholder=""
                            onChange={(e) => setFirstSourceCTAUrl(e.target.value)}
                          />
                          <span>*** Use these fields if you have only one source document. </span>
                        </div>
                        
                      </div>
                      
                    </Accordion.Body>
              
                  </Accordion.Item>  
                </Accordion>
              </div>
            </div>

            <div className="gap">&nbsp;</div>
          </div>
        </Col>
        <Col lg="7">
          <div>
            <div className="title1 color-primary">&nbsp;</div>
            <div className="gap">&nbsp;</div>
            <div className="p4">
              Use the below code fragments on your site to enable Elysia chatbot ...
            </div>
            <div className="gap">&nbsp;</div>
            <div className="settings-box">
              <InputGroup className="mb-3" style={{display:"block"}}>
                <div style={{ flexDirection: "column" }}>
                  {/* <div>
                    Paste the following CSS code in the document head.
                    <Form.Control
                      {...register("widget-css")}
                      className="shadow-sm bg-white rounded"
                      name="widget=css"
                      as="textarea"
                      rows={3}
                    />
                  </div> */}
                  <div style={{marginTop:"15px"}}>
                    Paste the following script tag at the end of the document body.
                    <Form.Control
                      {...register("widget-js")}
                      className="shadow-sm bg-white rounded"
                      name="widget=js"
                      as="textarea"
                      rows={3}
                    />
                  </div>
                  <div style={{marginTop:"15px"}}>
                    Paste the following html dom element just before the above script tag in
                    the document body.
                    <Form.Control
                      {...register("widget-html")}
                      className="shadow-sm bg-white rounded"
                      name="widget=html"
                      as="textarea"
                      rows={15}
                    />
                  </div>
                  <div style={{marginTop:"15px"}}>
                   Bind to the following triggers to open/close the chatbot
                    <Form.Control
                      {...register("widget-trigger")}
                      className="shadow-sm bg-white rounded"
                      name="widget=trigger"
                      as="textarea"
                      rows={10}
                    />
                  </div>
                </div>
              </InputGroup>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ChatWidgetGenerator;
