import { useContext, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { ReactComponent as ChatOptionsIcon } from "../../assets/icon-chat-options.svg";
import { ReactComponent as DocumentIcon } from "../../assets/icon-google-document.svg";
import { ReactComponent as PrivateChatIcon } from "../../assets/icon-private-chat.svg";
import { ReactComponent as SavedPromptsIcon } from "../../assets/icon-bookmark.svg";
import { ChatContext } from "../../context/ChatContext";

interface ChatWidgetProps {
    OnTogglePrivateChat: () => void;
    onAddSources: () => void;
    onInitiatedSavedPrompts: (boolean) => void;
}

export const ChatWidgets = ({OnTogglePrivateChat, onAddSources, onInitiatedSavedPrompts}: ChatWidgetProps) => {
    const [show, setShow] = useState(false);
    const { isPrivateChat, chatAgent } = useContext(ChatContext);
    const toggleMenu = () => setShow(!show);

    return (
        <>
            <Dropdown show={show} onToggle={toggleMenu} drop="up">
                <Dropdown.Toggle variant="light" id="chat-widget-dropdown" className="d-block d-md-none chat-widgets">
                    <ChatOptionsIcon />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item as="button" onClick={OnTogglePrivateChat} active={isPrivateChat}><PrivateChatIcon />Private chat</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={onAddSources} active={chatAgent === 'Document'}><DocumentIcon />Add sources</Dropdown.Item>
                    {!isPrivateChat && <Dropdown.Item as="button" onClick={onInitiatedSavedPrompts}><SavedPromptsIcon />Saved prompts</Dropdown.Item>}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};
