import { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";

import ChatSession from "../components/chat/ChatSession";

import MainHeader from "../components/home/MainHeader";
import { SamplePrompts } from "../components/home/SamplePrompts";
import ChatInput from "../components/chat/ChatInput";
import PrivateChat from "../components/chat/PrivateChat";

import { PromptContext } from "../context/PromptContext";
import { LoadingContext } from "../context/LoadingContext";
import { ChatContext } from "../context/ChatContext";
import { getMyProfile } from "../services/profile";
import notify from "../services/notify";
import { PROFILE_INCOMPLETE_TOAST_ID } from "../config/settings";

import { ReactComponent as ArrowDownwardsIcon } from "../assets/icon-arrow-downwards.svg";

let lastScrollTop = 0;

const Home = ({ contentRef }) => {
  const { isRestrictedChatSessionStatus, isChatActive, isPrivateChat, showScrollIcon, setShowScrollIcon, dynamicStreamCount } = useContext(ChatContext);
  const [prompt, setPrompt] = useState<string>("");

  const [isProcessed, setProcessed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  lastScrollTop = 0;

  const promptContext: PromptContextStore = {
    prompt,
    isProcessed,
    setPrompt,
    setProcessed,
  };

  const loadingContext: LoadingContextStore = {
    isLoading,
    setIsLoading,
  };

  useEffect(() => {
    if (window.location.pathname !== '/sandbox') {
      const containerElt = Array.from(
        document.getElementsByClassName('main-container') as HTMLCollectionOf<HTMLElement>,
      );
      containerElt.forEach(box => {
        box.style.overflowY = 'auto';
        box.style.marginTop = "20px";
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      let response;
      try {
        response = await getMyProfile();
      } catch (err) {
        console.error("error fetching profile details");
      }
      if (response && response[0]) {
        const isProfileComplete = response[0].doc.about && response[0].doc.skills.length && response[0].doc.topics_of_interest.length;
        if (!isProfileComplete && !(window.innerWidth < 768)) {
          const message = 'Your user profile is incomplete, please select <a data-testid="profile-link" href="/myprofile">settings</a> to update your details'
          const type = 'error'
          notify({
            message,
            type,
            toastId: PROFILE_INCOMPLETE_TOAST_ID,
            autoClose: false
          });
        }
      }
    })();
  }, []);

  useEffect(() => {
    let mainContentRef = contentRef.current;
    const handleStreamingScroll = () => {
      let currentScroll = mainContentRef.scrollTop;
      if ((currentScroll + mainContentRef.offsetHeight + 2) > mainContentRef.scrollHeight) {
        localStorage.setItem("autoScrollEnabled", "true");
        setShowScrollIcon?.(false);
      }
      else if (currentScroll + 2 < lastScrollTop) {
        localStorage.setItem("autoScrollEnabled", "false");
        setShowScrollIcon?.(true);
      }
      lastScrollTop = currentScroll;
    };
    mainContentRef?.addEventListener("scroll", handleStreamingScroll);
    window.addEventListener('resize', handleStreamingScroll);
    return () => {
      localStorage.setItem("autoScrollEnabled", "true");
      mainContentRef?.removeEventListener("scroll", handleStreamingScroll);
      window.removeEventListener('resize', handleStreamingScroll);
    };
    // eslint-disable-next-line
  }, [contentRef]);

  useEffect(() => {
    let mainContentRef = contentRef.current;
    if (localStorage.getItem("autoScrollEnabled") === "true") {
      checkScrollPosition();
    }
    if (mainContentRef.scrollHeight > mainContentRef.offsetHeight && (mainContentRef.scrollTop === 0)) {
      setShowScrollIcon?.(true);
    }
    // eslint-disable-next-line
  }, [dynamicStreamCount])

  const handleScrollToBottom = () => {
    contentRef.current.scrollTop = contentRef.current.scrollHeight;
  };

  let checkScrollPosition = () => {
    const parentDiv = document.getElementById('main-content');
    const targetClassName = 'user-msg-body';
    const targetElements = parentDiv?.getElementsByClassName(targetClassName);

    if (targetElements?.length && targetElements?.length > 0) {
      const lastTargetChild = targetElements[targetElements.length - 1];

      if (isElementAtTop(parentDiv, lastTargetChild)) {
        localStorage.setItem("autoScrollEnabled", "false");
        if (targetElements?.length && targetElements?.length > 1) {
          setShowScrollIcon?.(true)
        }
      }
    }
  };

  // Function to check if the target element is at the top of the parent
  const isElementAtTop = (parentElement, targetElement) => {
    const parentRect = parentElement.getBoundingClientRect();
    const targetRect = targetElement.getBoundingClientRect();

    // Check if the top of the target is at or above the top of the parent
    if (targetRect.top - 20 <= parentRect.top && targetRect.top > 0) {
      // correction of scroll to fix last user query at the top in view
      parentElement.scrollTop -= parentRect.top - targetRect.top;
      return true;
    }
    return false;
  }

  return (
    <>
      <div ref={contentRef} id="main-content" className="content-scrollable overflow-y-auto flex-grow-1 d-flex justify-content-center me-2 mt-4 chat-interface">
        <div className={`width-control ${isChatActive ? '' : 'landing-view align-self-md-center'}`}>
          {!isChatActive && isPrivateChat &&
            <PrivateChat />
          }
          {!isChatActive && !isPrivateChat &&
            <MainHeader />
          }
          <LoadingContext.Provider value={loadingContext}>
            <PromptContext.Provider value={promptContext}>
              {!isChatActive && !isPrivateChat &&
                <SamplePrompts />
              }
              <ChatSession />
            </PromptContext.Provider>
          </LoadingContext.Provider>
        </div>
      </div>
      {!isRestrictedChatSessionStatus && (
        <div className="content-fixed-bottom d-flex justify-content-center chat-interface">
          <div className="width-control-bottom position-relative">
            {showScrollIcon && (
              <Button aria-label="scroll" data-testid="scroll-to-bottom-button" className="scroll-icon-button" onClick={handleScrollToBottom}><ArrowDownwardsIcon /></Button>
            )}
            <LoadingContext.Provider value={loadingContext}>
              <PromptContext.Provider value={promptContext}>
                <ChatInput />
              </PromptContext.Provider>
            </LoadingContext.Provider>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;