import { catchError } from "./common";
import axios from "axios";

export const getDashboardToken = async (): Promise<any> => {
    try {
        const data = {
            "datasets": [{
                "id": `${process.env.REACT_APP_POWERBI_DATASET_ID}`
            }],
            "reports": [{
                "id": `${process.env.REACT_APP_POWERBI_REPORT_ID}`
            }]
        }
        const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}v1/ai/dashboard/powerbi`, data);
        return response.data;
    } catch (e: any) {
        console.error(e);
        catchError(e);
    }
}
