import { createContext } from 'react';

const initialHistoryContextValue: HistoryContextStore = {
    today: [],
    yesterday: [],
    last_7_days: [],
    last_30_days: [],
    archived_chats: []
};

interface HistoryFunctions {
    addChatToToday: (newChat: Chat) => void;
    updateSessionTitle: (sessionId: string, newTitle: string) => void;
    renameSessionTitle: (sessionId: string, newTitle: string) => void;
    updateChatHistoryOnDelete: (session_id: String) => void;
    updateChatHistoryOnArchive: (session_id: String) => void;
}

export const HistoryFunctionsContext = createContext<HistoryFunctions | undefined>(undefined);
export const HistoryDataContext = createContext<HistoryContextStore >(initialHistoryContextValue);