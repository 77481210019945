import { useContext, useCallback } from "react";
import Form from 'react-bootstrap/Form';
import { Button } from "react-bootstrap";
import { useDropzone } from 'react-dropzone'
import { ChatContext } from "../../context/ChatContext";
import notify from "../../services/notify";
import { ReactComponent as UploadBackIcon } from "../../assets/icon-backwards.svg";

import icon_elysia_brain from "../../assets/icon-elysia-brain.svg";
import icon_upload_solid from "../../assets/icon-upload.svg";

import "./style.scss";

const UploadFile = (props) => {
    const { onCollectionChange, register, setLoading, setUploadedFile, setValue, setPageState } = props;
    const { allowedCollections } = useContext(ChatContext);

    //for file upload
    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles && acceptedFiles[0]) {
            setLoading(false);
            setUploadedFile(acceptedFiles[0]);
            setValue("uploadedfile-title", acceptedFiles[0].path);
            setPageState("save");
        } else {
            notify({
                message: 'Please upload a single file of allowed formats and size.',
                type: 'error'
            });
        }

    }, [setLoading, setUploadedFile, setValue, setPageState])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'text/plain': ['.txt'],
            'application/pdf': ['.pdf'],
            // 'application/epub+zip': ['.epub'],
            // 'application/xml': ['.xml']
            // 'application/vnd.openxmlformats-officedocument.presentationml.presentation' : ['.pptx'],
            // 'application/vnd.ms-powerpoint' :['.ppt'],
            // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : ['.docx'],
            // 'application/vnd.ms-powerpoint.presentation.macroEnabled.12' : ['.pptm'],
            // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : ['.xlsx'],
            // 'application/vnd.ms-excel.sheet.macroEnabled.12' : ['.xlsm']
        },
        maxSize: 209715200, // 200mb
        multiple: false
    });

    return (
        <>
            <div className="d-flex">
                <Button aria-label="back" data-testid="upload-back-button" className="backwards-icon" onClick={() => setPageState("manage")}><UploadBackIcon /></Button>
                <div className="title1 color-secondary ms-3" data-testid="upload-file"> Upload File </div>
            </div>
            <div className="system-info-msg file-upload">
                <img src={icon_elysia_brain} alt="Elysia" /> &nbsp; Select a collection and then choose a file
            </div>
            <div className="d-flex align-items-center mb-4">
                <div> Collection: </div>
                <div className="ms-2 me-4">
                    <Form.Select
                        className="upload-collection-list"
                        {...register("collection-list")}
                        data-testid="collection-dropdown"
                        onChange={onCollectionChange}
                        aria-label="collection"
                        autoComplete="off"
                    >
                        <option value="private||My Private Collection|myCollection">My Private Collection</option>
                        {allowedCollections?.filter(collection => collection.perm === "manage").map(({ ckey, cline, ctype, cname }, index) => <option key={index} value={ctype + '|' + cline + '|' + cname + '|' + ckey} >{cname}</option>)}
                    </Form.Select>
                </div>
                <div className="ml-2" data-testid="file-limit-text" >
                    Choose a file (Limit 200MB)<span className="mandatory-asterisk">*</span>
                </div>
            </div>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="content-dragzone-div text-center d-flex align-items-center">
                    <div className="upload-left-panel" data-testid="drag-and-drop-box">
                        <div className="drag-drop-container">
                            <div className="p-2"><img src={icon_upload_solid} alt="upload" /></div>
                            <div>Drag and drop a file here</div>
                            <div data-testid="file-format-extensions">(*.txt, *.pdf)</div>
                        </div>
                    </div>
                    <div className="upload-right-panel" data-testid="browse-files-box"><div className="browse-files p-2">Browse Device</div></div>
                </div>
            </div>
        </>
    );
};

export default UploadFile;
