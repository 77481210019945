import { catchError } from "./common";
import axios from "axios";

export const savePrompt  = async (user, prompt: string): Promise<any> => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}v1/ai/chat/users/${user?.signInUserSession?.idToken?.payload?.sub}/prompts`, {
        prompt:prompt
      });
      return response.data.data;
    } catch (e: any) {
      console.error(e);
      catchError(e);
    }
  }

  export const getSavedPrompts  = async (user): Promise<any> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}v1/ai/chat/users/${user?.signInUserSession?.idToken?.payload?.sub}/prompts`);
      return response.data.data;
    } catch (e: any) {
      console.error(e);
      catchError(e);
    }
  }

  export const getSamplePrompts  = async (): Promise<any> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}v1/ai/chat/prompts/sample`);
      return response.data.data;
    } catch (e: any) {
      console.error(e);
      catchError(e);
    }
  }

  export const deleteSavedPrompts  = async (promptId: Number): Promise<any> => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_DOMAIN}v1/ai/chat/prompt/${promptId}`);
      return response.data;
    } catch (e: any) {
      console.error(e);
      catchError(e);
    }
  }