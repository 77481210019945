import React from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeBlock = ({
  node,
  inline,
  className,
  children,
  ...props
}: any) => { //TODO: find a fix for any
  const match = /language-(\w+)/.exec(className || '')
  const language = match ? match[1] : 'text'; // Use 'text' as fallback
  return !inline && match ? (
    <SyntaxHighlighter
      style={a11yDark}
      language={language}
      PreTag='div'
      wrapLongLines={true}
      {...props}
      children={String(children).replace(/\n$/, '')}
    >
    </SyntaxHighlighter>
  ) : (
    <code className='text-break-space' {...props}>
      {children}
    </code>
  )
}

export default CodeBlock