import { useContext, useEffect, useState, ChangeEvent, useRef } from "react";
import { useForm } from "react-hook-form";
import { InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation } from "react-router-dom";

import { PromptContext } from "../../context/PromptContext";
import { ChatContext } from "../../context/ChatContext";
import { LoadingContext } from "../../context/LoadingContext";
import { AppContext } from "../../context/AppContext";
import icon_loading from "../../assets/elysia-loader.gif";
import icon_loading_stopped from "../../assets/icon-loading-stopped.svg";
import { ReactComponent as DocumentIcon } from "../../assets/icon-google-document.svg";
import { ReactComponent as SubmitPromptIcon } from "../../assets/icon-send.svg";
import { ReactComponent as StopPromptIcon } from "../../assets/icon-google-stop.svg";
import { ReactComponent as PrivateChatIcon } from "../../assets/icon-private-chat.svg";
import ChatNavItems from "../home/ChatNavItems";
import { HISTORY_ZERO_STATE } from "../../constants/HistoryConstants";
import { generateChatSessionId } from "../../utils/chat";

import { PROMPT_WORD_LENGTH } from "../../config/settings";
import notify from "../../services/notify";
import { ChatSavedPrompt } from "./ChatSavedPrompt";
import { ChatWithDoc } from "../home/ChatWithDoc";
import { ChatWidgets } from "./ChatWidgets";
import OverlayTooltip from "../common/UI/OverlayTooltip";


const ChatInput = () => {
  const { register } = useForm();
  const { setPrompt, setProcessed } = useContext(PromptContext);
  const { sources, promptInput, chatAgent, clearState, newChat, setIsChatActive, setPromptInput, setChatAgent, setEntryPoint, setClearState, setActiveChatHistoryRecord, setSessionPath, setNewChat, setChatSessionId, setNextBestActions, setSelectedSources, setIsRestrictedChatSessionStatus, isStreamingRequested, setIsStreamingRequested, isPrivateChat, setIsPrivateChat, setShowScrollIcon } = useContext(ChatContext);
  const { isTouchDevice } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  if (window.location.pathname === "/" && queryParams.get("private-chat") === "true") {
    setIsPrivateChat?.(true);
  }
  const { isLoading } = useContext(LoadingContext);
  const { activeChatHistoryRecord } = useContext(ChatContext);
  const [placeholderText, setPlaceHolderText] = useState("");
  const [isPromptError, setIsPromptError] = useState<boolean>(false);
  const [showDocPopup, setShowDocPopup] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [initiatedSavedPrompts, setInitiatedSavedPrompts] = useState(false);

  const textareaRef = useRef(document.createElement('textarea'));
  const maxPromptHeight = 13 * 22; // show 12 lines of prompt before scrolling. 13 used to account for padding at the top. 22 is line height;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Set the initial value
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    switch (chatAgent) {
      case "Document": {
        setPlaceHolderText(ChatNavItems[0].placeHolderText);
        break;
      }
      default: {
        setPlaceHolderText("Ask Elysia anything");
        break;
      }
    }
    // eslint-disable-next-line  
  }, [chatAgent, isPrivateChat]);

  useEffect(() => {
    textareaRef.current.style.height = 'auto'

    if (textareaRef.current.scrollHeight > maxPromptHeight) {
      textareaRef.current.style.height = `${maxPromptHeight}px`;
      textareaRef.current.style.overflowY = 'auto'
    } else {
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      textareaRef.current.style.overflowY = 'hidden'
    }
    //eslint-disable-next-line
  }, [promptInput.length]);

  useEffect(() => {
    if(!isTouchDevice) {
      textareaRef.current.focus();
    }
    // eslint-disable-next-line
  }, [clearState, newChat]);

  useEffect(() => {
    setPromptInput("");
    // eslint-disable-next-line
  }, [activeChatHistoryRecord?.session_id]);


  function wordCount(text = '') {
    return text.split(/\S+/).length - 1;
  };

  const stopStreaming = () => {
    localStorage.setItem("stopStreaming", "true");
    setIsStreamingRequested?.(false);
  }

  const submitPrompt = () => {
    if (promptInput) {
      if (wordCount(promptInput) < PROMPT_WORD_LENGTH) {
        if (chatAgent === 'Document' && sources?.length === 0) {
          notify({
            message: 'Please select a document to proceed.',
            type: 'error'
          });

        } else {
          setIsPromptError(false);
          setClearState?.(false);
          setEntryPoint?.("prompt");
          setPrompt?.(promptInput.trim());
          setProcessed?.(false);
          setPromptInput('');
        }
      } else {
        notify({
          message: 'You have reached the maximum character limit.',
          type: 'error'
        });
        setIsPromptError(true);
      }
    } else {
      console.error("Please enter your question in the box provided.")
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      // for a touch enabled device, enter will add a new line and not submit the prompt
      //TODO: Need refining for external keyboard and hybrid devices
      if (isTouchDevice) {
        return;
      }
      if (e.shiftKey) {
        return;
      }
      e.preventDefault();
      if (!isLoading) {
        submitPrompt();
      }
    }
  };

  const createNewSession = () => {
    setClearState?.(true);
    setNewChat?.(true);
    setChatSessionId?.(generateChatSessionId());
    setActiveChatHistoryRecord(HISTORY_ZERO_STATE);
    navigate({
      pathname: "/",
      search: queryParams.toString()
    });
    setIsChatActive?.(false);
    setPromptInput("");
    setSessionPath?.("");
    setIsRestrictedChatSessionStatus?.("");
  }

  const handleAddSources = () => {
    setShowDocPopup(true);
    if (chatAgent !== "Document") {
      setSelectedSources?.([]);
      setChatAgent?.("Document");
      setNextBestActions?.(ChatNavItems[0].nextBestActions);
      createNewSession();
    }
  }

  const handleInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const enteredInput = event.target.value;
    setPromptInput(enteredInput);
    setIsPromptError(false);
  }

  const togglePrivateChat = () => {
    createNewSession();
    setChatAgent?.("Generic"); // set default agent when toggled

    if (isPrivateChat) {
      queryParams.delete("private-chat");
    } else {
      queryParams.set("private-chat", "true");
    }
    navigate({
      pathname: "/",
      search: queryParams.toString()
    });

    setIsPrivateChat?.(!isPrivateChat);
    setShowScrollIcon?.(false);
  }

  return (
    <>
      <div className="chat-input">
        {isLoading && (
          <div className="chat-input-loading"><img src={icon_loading} alt="loading" /></div>
        )}
        {!isLoading && (
          <div className="chat-input-loading"><img src={icon_loading_stopped} alt="not_loading" /></div>
        )}

        <div className="w-100">
          <InputGroup className={`chat-input-group ${isPrivateChat ? 'private-chat' : ''} ${isMobile ? 'is-mobile' : ''}`}>
            <OverlayTooltip
              tooltipText="Private Chat"
              buttonContent={<PrivateChatIcon />}
              ariaLabel="Private Chat"
              testId="private-chat"
              tooltipClassName="tooltip-sidebar"
              buttonClassName={`private-chat ${isPrivateChat === true ? "selected" : ""}`}
              onClick={togglePrivateChat}
              placement="top-start"
            />

            <OverlayTooltip
              tooltipText="Add Sources"
              buttonContent={<DocumentIcon />}
              ariaLabel="Add Sources"
              testId="add-sources"
              tooltipClassName="tooltip-sidebar"
              buttonClassName={`add-sources ${chatAgent === "Document" ? "selected" : ""}`}
              onClick={() => handleAddSources()}
              placement="top-start"
            />
            {showDocPopup &&
              <ChatWithDoc setShowDocPopup={setShowDocPopup} />
            }
            <ChatSavedPrompt initiated={initiatedSavedPrompts} setInitiated={setInitiatedSavedPrompts} />
            <ChatWidgets OnTogglePrivateChat={togglePrivateChat} onAddSources={handleAddSources} onInitiatedSavedPrompts={setInitiatedSavedPrompts} />
            <Form.Control {...register('chat-input')}
              className={`shadow-sm bg-white rounded w-100 ${chatAgent === "Document" ? "chat-with-doc" : ""}`}
              aria-label={placeholderText}
              placeholder={placeholderText}
              name="chat-input"
              as="textarea"
              data-testid="chat-prompt-input"
              rows={1}
              value={promptInput}
              ref={textareaRef}
              onKeyDown={handleKeyDown}
              onInput={handleInput}
              id="prompt-input"
              autoComplete="off"
            />
            {isStreamingRequested ? (
              <Button aria-label="Stop Streaming" className="stop-button prompt-input-button active" data-testid="stop-streaming" onClick={stopStreaming} >
                <StopPromptIcon />
              </Button>
            ) : (
              <Button aria-label="Submit Prompt" className={`submit-prompt prompt-input-button ${promptInput.trim().length > 0 ? 'active' : ''}`} data-testid="submit-prompt" onClick={submitPrompt} disabled={isLoading || isPromptError || !promptInput.trim().length}>
                <SubmitPromptIcon />
              </Button>
            )}
            <div className="chat-mode-container d-block d-md-none">
              {isPrivateChat && <Button aria-label="Private Chat" data-testid="private-chat" onClick={togglePrivateChat}>
                <PrivateChatIcon />
              </Button>}
              {chatAgent === "Document" && <Button aria-label="Add sources" data-testid="add-sources" onClick={() => handleAddSources()}>
                <DocumentIcon />
              </Button>}
            </div>
          </InputGroup>
          <div data-tesid="disclaimer-message" className="chat-input-protection-msg text-start text-md-center">Elysia may display inaccurate information, always consider checking information that you may rely on.</div>
        </div>
      </div>
    </>
  );
};

export default ChatInput;
