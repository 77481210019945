import { Auth } from 'aws-amplify';
import axios from 'axios';

import awsconfig from '../config/aws';
import { clearTrackId, trackId } from '../utils/tracker';
import { getUserEmail } from '../utils/user';

export const checkUser = async () => {
    const {
        oauth: { domain, scope, responseType, auth0_identity_provider, redirectSignIn },
        aws_user_pools_web_client_id,
    } = awsconfig;
    try { 
        const user = await Auth.currentAuthenticatedUser({bypassCache: true});         
        const currentSession = await Auth.currentSession();   
        if (currentSession.getAccessToken().getExpiration() > Math.floor(Date.now()/1000) ) {
          (axios.defaults.headers).common['Authorization'] = 'Bearer ' + currentSession.getAccessToken().getJwtToken();
          //refresh the session  
          user.refreshSession(currentSession.getRefreshToken(), (err, session) => {
            const { accessToken } = session;                  
            (axios.defaults.headers).common['Authorization'] = 'Bearer ' + accessToken.jwtToken;          
          }); 
          if(user) trackId(getUserEmail(user.username));
          return user;  
        } else {
          throw new Error("Expired Session")
        }
    } catch(error) {
        console.log(error)
        const url = new URL(
            `https://${domain}/oauth2/authorize?identity_provider=${auth0_identity_provider}&redirect_uri=${redirectSignIn}&response_type=${responseType.toUpperCase()}&client_id=${aws_user_pools_web_client_id}&scope=${scope.join(
            ' '
            )}`
        );
        window.location.assign(url.toString());        
        return;
    };
};

export const logout = async () => {
    const {
      oauth: { domain, scope, responseType, redirectSignOut },
      aws_user_pools_web_client_id,
    } = awsconfig;
    clearTrackId();
    // Clear any old cognito session if it is not cleared by Auth.SignOut
    const items = { ...localStorage };
    for (const a in items) {
      if (a.startsWith('CognitoIdentityServiceProvider') || a.includes('amplify')) {
        localStorage.removeItem(a);
        localStorage.removeItem('userLoggedForFirstTime');
      }
    }
    const url = new URL(
      `https://${domain}/logout?response_type=${responseType}&client_id=${aws_user_pools_web_client_id}&redirect_uri=${redirectSignOut}&state=STATE&scope=${scope.join(
        ' '
      )}`
    );
    window.location.assign(url.toString());
};