import React, { useState, FC } from 'react';
import { AppContext } from '../context/AppContext';

interface Props {
    children: JSX.Element,
    user: any
}

export const AppProvider: FC<Props> = ({ children, user }) => {

    const [isSideNavOpen, setIsSideNavOpen] = useState<boolean>(localStorage.getItem('sidebarOpen') === 'true');
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    const appContext: AppContextStore = {
        isSideNavOpen,
        isTouchDevice,
        setIsSideNavOpen,
        setIsTouchDevice,
    };


    return (
        <AppContext.Provider value={appContext}>
            {user ?
                children
                : ''
            }
        </AppContext.Provider>
    )
}