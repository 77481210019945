import { useContext, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { SettingContext } from "../../context/SettingContext";

import { ReactComponent as SelectIcon } from "../../assets/icon-google-chevron-right.svg";
import { ReactComponent as BackIcon } from "../../assets/icon-backwards.svg";
import { PERSONALITY, CREATIVITY, ROLE, DOMAIN_EXPERTISE, WRITING_STYLE, OUTPUT_LANGUAGE } from "../../config/settings";
import { BackToChatButton } from "../common/BackToChatButton";
import notify from "../../services/notify";

const MySettings = () => {
  const {
    personality,
    creativity,
    role,
    domainExpertise,
    writingStyle,
    outputLanguage,
    setPersonality,
    setCreativity,
    setRole,
    setDomainExpertise,
    setWritingStyle,
    setOutputLanguage,
  } = useContext(SettingContext);

  const [settingOptionsState, setSettingOptionsState] = useState("all");
  const [settingOptions, setSettingOptions] = useState<string[]>([]);
  const [activeItem, setActiveItem] = useState("");

  const navigateToSetting = (state) => {
    setSettingOptionsState(state);

    if (state === "Personality") {
      setSettingOptions?.(PERSONALITY);
      setActiveItem(personality);
    }
    if (state === "Creativity") {
      setSettingOptions?.(CREATIVITY);
      setActiveItem(creativity);
    }
    if (state === "Role") {
      setSettingOptions?.(ROLE);
      setActiveItem(role);
    }
    if (state === "Domain of Expertise") {
      setSettingOptions?.(DOMAIN_EXPERTISE);
      setActiveItem(domainExpertise);
    }
    if (state === "Writing Style") {
      setSettingOptions?.(WRITING_STYLE);
      setActiveItem(writingStyle);
    }
    if (state === "Output Language") {
      setSettingOptions?.(OUTPUT_LANGUAGE);
      setActiveItem(outputLanguage);
    }
  };

  const setSettingSelection = (selection) => {
    if (settingOptionsState === "Personality") setPersonality(selection);
    if (settingOptionsState === "Creativity") setCreativity(selection);
    if (settingOptionsState === "Role") setRole(selection);
    if (settingOptionsState === "Domain of Expertise") setDomainExpertise(selection);
    if (settingOptionsState === "Writing Style") setWritingStyle(selection);
    if (settingOptionsState === "Output Language") setOutputLanguage(selection);
    setActiveItem(selection);
    notify({
      message: 'Your Elysia configuration has been successfully updated.',
      type: 'success'
    });
  };

  return (
    <>
      <BackToChatButton />
      <div style={{ display: settingOptionsState === "all" ? "block" : "none", paddingBottom: "20px" }}>
        <div>
          <div className="title1 mb-4" data-testid="configurations">
            Configurations
          </div>
          <div className="mb-4" data-testid="configurations-sub-heading">You can customize and manage various settings related to your chat experience. Take control of your conversations and make them more efficient with the following options:</div>
        </div>
        <div className="confugrations-table">
          <div className="prompt-and-responses-text" data-testid="prompt-and-responses-heading">Prompts & Responses</div>
          <Table bordered hover className="custom-table">
            <tbody>
              <tr>
                <td className="table-data width-column" data-testid="personality">Personality</td>
                <td className="table-data" data-testid="personality-value"> {personality}</td>
                <td className="icon-align" >
                  <Button
                    aria-label="select personality"
                    data-testid="select-personality"
                    className="forward-arrow-icon"
                    onClick={() => {
                      navigateToSetting("Personality");
                    }}> <SelectIcon />
                  </Button>
                </td>
              </tr>
              <tr>
                <td className="table-data width-column" data-testid="creativity">Creativity</td>
                <td className="table-data" data-testid="creativity-value">{creativity}</td>
                <td className="icon-align">
                  <Button
                    aria-label="select creativity"
                    data-testid="select-creativity"
                    className="forward-arrow-icon"
                    onClick={() => {
                      navigateToSetting("Creativity");
                    }}> <SelectIcon />
                  </Button>
                </td>
              </tr>
              <tr>
                <td className="table-data width-column" data-testid="role">Role</td>
                <td className="table-data" data-testid="role-value">{role}</td>
                <td className="icon-align">
                  <Button
                    aria-label="select role"
                    data-testid="select-role"
                    className="forward-arrow-icon"
                    onClick={() => {
                      navigateToSetting("Role");
                    }}> <SelectIcon />
                  </Button>
                </td>
              </tr>
              <tr>
                <td className="table-data width-column" data-testid="domain-expertise">Domain of Expertise</td>
                <td className="table-data" data-testid="domain-expertise-value">{domainExpertise}</td>
                <td className="icon-align">
                  <Button
                    aria-label="select domain of expertise"
                    data-testid="select-domain-expertise"
                    className="forward-arrow-icon"
                    onClick={() => {
                      navigateToSetting("Domain of Expertise");
                    }}> <SelectIcon />
                  </Button>
                </td>
              </tr>
              <tr>
                <td className="table-data width-column" data-testid="writing-style">Writing Style</td>
                <td className="table-data" data-testid="writing-style-value">{writingStyle}</td>
                <td className="icon-align">
                  <Button
                    aria-label="select writing style"
                    data-testid="select-writing-style"
                    className="forward-arrow-icon"
                    onClick={() => {
                      navigateToSetting("Writing Style");
                    }}> <SelectIcon />
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div
        style={{ display: settingOptionsState !== "all" ? "block" : "none", paddingBottom: "20px" }}
      >
        <div style={{ display: "flex" }}>
          <Button
            aria-label="back"
            data-testid="backwards-icon"
            className="backwards-icon"
            onClick={() => {
              navigateToSetting("all");
            }}
          >
            <BackIcon />
          </Button>
          <div className="title1 color-secondary mb-4 ps-3" data-testid={settingOptionsState} >
            {settingOptionsState}
          </div>
        </div>
        <div className="p4 mb-2" data-testid="configuration-response-heading">Select Elysia's response {settingOptionsState.toLowerCase()}</div>
        <div className="confugrations-table">
          <div className="prompt-and-responses-text" data-testid="configuration-response-sub-heading">Select {settingOptionsState}</div>
          <Form>
            {settingOptions?.map((item) => {
              return (
                <>
                  <Form.Check className="custom-radio" checked={activeItem === item ? true : false} type="radio" name={settingOptionsState} onClick={() => setSettingSelection(item)} aria-label={item} label={item}></Form.Check>
                  <hr className="horizontal-line" />
                </>
              );
            })}
          </Form>
        </div>
      </div>
    </>
  );
};

export default MySettings;
