import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChatContext } from '../../context/ChatContext';
import { Anchor, Dropdown } from 'react-bootstrap';
import { HISTORY_ZERO_STATE } from "../../constants/HistoryConstants";
import { deleteChatHistoryRecord, archiveChatHistoryRecord } from "../../services/history";
import { useHistoryFunctions } from "../../providers/historyProvider";
import { ReactComponent as MoreIcon } from "../../assets/icon-google-more-horz.svg";
import { ReactComponent as ArchiveIcon } from "../../assets/icon-archive.svg";
import { ReactComponent as RenameIcon } from "../../assets/icon-edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icon-google-delete.svg";
import { ReactComponent as TickIcon } from "../../assets/icon-google-tick.svg";
import { formatResponse, generateChatSessionId } from '../../utils/chat';
import { CustomDropdownItem } from "../common/UI/CustomDropdownItem";

export const ChatHistoryContextMenu = (props) => {
    const { chatConversationMetadata, setTitle, setShowInputForRename, pathname } = props;
    const { setIsChatActive, setPromptInput, setNewChat, setChatSessionId, setClearState, setChatAgent, setActiveChatHistoryRecord, setSessionPath, setShowScrollIcon } = useContext(ChatContext);
    const navigate = useNavigate();
    const [showBadge, setShowBadge] = useState(false);
    const [badgeText, setBadgeText] = useState<String>("");
    const [showDropdown, setShowDropdown] = useState(false);

    const historyFunctions = useHistoryFunctions();
    if (!historyFunctions) {
        throw new Error('History functions are not available.');
    }
    const { updateChatHistoryOnDelete, updateChatHistoryOnArchive } = historyFunctions;

    const startNewTopicChat = () => {
        setShowScrollIcon?.(false);
        setClearState?.(true);
        setNewChat?.(true);
        setChatSessionId?.(generateChatSessionId());
        setChatAgent?.("Generic");
        setActiveChatHistoryRecord(HISTORY_ZERO_STATE);
        navigate("/");
        setPromptInput("");
        setIsChatActive?.(false);
        setSessionPath?.("");
    };

    const renameTitle = () => {
        setTitle(formatResponse(chatConversationMetadata?.chat_history_title, true));
        setShowInputForRename(true);
    }

    const handleBadge = (badgeText: String) => {
        setBadgeText(badgeText);
        setShowBadge(true);
        const timeoutRef = setTimeout(() => {
            setShowBadge(false);
            clearTimeout(timeoutRef);
            if (badgeText === "Deleted history") {
                updateChatHistoryOnDelete(chatConversationMetadata.session_id);
            } else {
                updateChatHistoryOnArchive(chatConversationMetadata.session_id);
            }
        }, 2000);
    }

    const handleDeleteHistoryClick = () => {
        let response;

        (async () => {
            try {
                response = await deleteChatHistoryRecord(chatConversationMetadata.session_id);
                if (pathname === chatConversationMetadata.session_id) {
                    startNewTopicChat();
                }
                if (response) {
                    handleBadge("Deleted history");
                    setShowDropdown(false);
                }
            } catch (err) {
                console.log("error deleting chat record");
            }
        })();
    }

    const handleArchiveHistoryClick = () => {
        let response;
        (async () => {
            try {
                response = await archiveChatHistoryRecord(chatConversationMetadata.session_id);
                if (response) {
                    handleBadge("Archived history");
                    setShowDropdown(false);
                }
            } catch (err) {
                console.log("error archiving chat record");
            }
        })();
    }


    return (
        <>
            <Dropdown align="end" className="d-inline mx-2 more-options" show={showDropdown} onToggle={(isOpen) => setShowDropdown(isOpen)}>
                <Dropdown.Toggle as={Anchor} aria-label="more options" className="more-icon-dropdown" aria-haspopup="true">
                    <MoreIcon />
                </Dropdown.Toggle>

                <Dropdown.Menu className='dropdown-context-menu' role='menu'>
                    {!chatConversationMetadata.is_archived && (
                        <CustomDropdownItem className='archive-dropdown-item' onClick={handleArchiveHistoryClick}>
                            <div className="d-flex align-items-center">
                                <ArchiveIcon />
                                <span>Archive</span>
                            </div>
                        </CustomDropdownItem>
                    )}
                    <CustomDropdownItem className='rename-dropdown-item' onClick={renameTitle}>
                        <div className="d-flex align-items-center">

                            <RenameIcon />
                            <span>Rename</span>
                        </div>
                    </CustomDropdownItem>
                    <CustomDropdownItem className='delete-dropdown-item' onClick={handleDeleteHistoryClick}>
                        <div className="d-flex align-items-center">
                            <DeleteIcon />
                            <span>Delete</span>
                        </div>
                    </CustomDropdownItem>
                </Dropdown.Menu>
            </Dropdown>
            {showBadge && (
                <span className="history-badge position-absolute">
                    <TickIcon />
                    <span>{badgeText}</span>
                </span>
            )}
        </>
    );
};
