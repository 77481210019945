import { useContext } from "react";
import ChatUserMessage from "./ChatUserMessage";
import SystemProtectionMessage from "./SystemProtectionMessage";
import ChatSystemMessage from "./ChatSystemMessage";
import RestrictedSessionErrorMessage from "./RestrictedSessionErrorMessage";
import { ChatContext } from "../../context/ChatContext";

export type Message = {
  type: string; //User | System
  body: string | null | undefined;
  prompts?: { title: string; type: string }[];
  relatedList?: { title: string; url: string }[];
  showInteractions?: boolean;
  runId?: string //used to log like/dislike to langsmith
  references?: MetadataSource[];
  toolResult?: SourceInteraction[];
  toolCall?: string;
};

const ChatMessage = (props) => {
  const { isRestrictedChatSessionStatus } = useContext(ChatContext);

  return (
    <>
      {props?.message?.type === "System-Default" && (
        <>
          <SystemProtectionMessage />
          {isRestrictedChatSessionStatus && (
            <RestrictedSessionErrorMessage isRestrictedChatSessionStatus={isRestrictedChatSessionStatus} />
          )}
        </>
      )}
      {!isRestrictedChatSessionStatus && (
        <>
          {props?.message?.type === "System" && (
            <ChatSystemMessage message={props.message} historySystemMessage={props.historySystemMessage} />
          )}
          {props?.message?.type === "User" && (
            <ChatUserMessage messageBody={props?.message?.body} />
          )}
          <div style={{ clear: "both" }}></div>
        </>
      )}
    </>
  );
};

export default ChatMessage;
