import axios from "axios";
import { catchError } from "./common";
import qs from "qs";


export const getLeadProfile = async (
  profileName?: string
): Promise<any> => {
  let outHTML = "<div>"
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}v1/ai/profile/search?profile_name=${profileName}`);
    let profile = response.data.data.answer;
    outHTML += (profile.full_name) ? `<p><strong>Full Name: </strong>${profile.full_name}` : ""
    outHTML += (profile.job_title) ? ` <strong>Job Title: </strong>${profile.job_title}</p>\r\n \r\n` : "";
    outHTML += (profile.summary) ? `<p><strong>About Me: </strong>${profile.summary}</p>\r\n \r\n` : "";
    outHTML += (profile.skills) ? `<p><strong>Skills: </strong>${profile.skills.join(", ")}</p>\r\n \r\n` : "";
    outHTML += (profile.topic_of_interest) ? `<p><strong>Topics of Interest: </strong>${profile.topic_of_interest.join(", ")}</p>\r\n \r\n` : "";
    outHTML += (profile.linkedin_url) ? `<p>LinkedIn Profile: <a href="${profile.linkedin_url}">${profile.full_name}</a></p>\r\n \r\n` : "";
  } catch (e: any) {
    console.log(e)
    outHTML += `Sorry! I am not able to fetch information about ${profileName} at this time. Please try again later!`;
  }
  outHTML += "</div>"
  return outHTML;
};

export const getMyProfile = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}v1/ai/profile/self`
    );
    return response.data.data;
  } catch (e: any) {
    console.log(e);
    catchError(e);
    //return not used, keep typescript happy
    let retObj: any;
    return retObj;
  }
}

export const getMySocialProfile = async (name, url): Promise<any> => {
  try {
    let data = { name: name, url: url }
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}v1/ai/profile/self/social?${qs.stringify(data)}`
    );
    return response.data.data;
  } catch (e: any) {
    console.log(e);
    catchError(e);
    //return not used, keep typescript happy
    let retObj: any;
    return retObj;
  }
}

export const updateLinkedIn = async (name: string, url: string): Promise<any> => {
  try {
    let data = {
      name,
      url
    }
    const response = await axios.put(
      `${process.env.REACT_APP_API_DOMAIN}v1/ai/profile/update`, data
    );
    return response;
  } catch (e: any) {
    console.error(e);
    return null;
  }
}

export const updateMyProfile = async (profileInfo: ProfileInfo): Promise<any> => {
  try {
    let data = {
      email: profileInfo.email,
      division: "",
      employee_number: profileInfo.employee_number,
      full_name: profileInfo.fullName,
      phone: "",
      job_function: "",
      job_title: "",
      job_type: "",
      location: "",
      opted_out: profileInfo.optOut,
      photo: profileInfo.photo,
      about: profileInfo.about,
      linkedin: profileInfo.linkedin,
      skills: profileInfo.skills,
      topics_of_interest: profileInfo.interests,
      timezone: ""
    }
    const response = await axios.put(
      `${process.env.REACT_APP_API_DOMAIN}v1/ai/profile/self`, data
    );
    return response.data.data;
  } catch (e: any) {
    console.error(e);
    catchError(e);
    return null
  }
}

export const getSkills = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}v1/ai/profile/skills`
    );
    return response.data.data;
  } catch (e: any) {
    console.log(e);
    catchError(e);
    //return not used, keep typescript happy
    let retObj: any;
    return retObj;
  }
}

export const getInterests = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}v1/ai/profile/interests`
    );
    return response.data.data;
  } catch (e: any) {
    console.log(e);
    catchError(e);
    //return not used, keep typescript happy
    let retObj: any;
    return retObj;
  }
}

export const getAllowedCollections = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}v1/ai/profile/self/collections`
    );
    return response.data.data;
  } catch (e: any) {
    console.log(e);
    catchError(e);
    //return not used, keep typescript happy
    let retObj: any;
    return retObj;
  }
}

export const getAllowedRoutes = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}v1/ai/profile/self/resources`
    );
    const data = response.data.data;
    const routesMap = {
      'power_bi_dashboard': '/dashboard',
      'elysia_sandbox': '/sandbox',
    }

    const allowedRoutes = data.filter((asset) => {
      return asset.perm === 'read' || asset.perm === 'write';
    }).map((asset) => {
      return routesMap[asset.asset_name];
    });
    return allowedRoutes;
  } catch (e: any) {
    console.log(e);
    catchError(e);
    //return not used, keep typescript happy
    let retObj: any;
    return retObj;
  }
}

