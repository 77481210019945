export const ChatSources = (props) => {
    const { references } = props;
    return (
        <>
            <div className="sys-msg-sources">
                <div>
                    <div className="sys-msg-sources-label">Sources: </div>
                    {references?.map((item, index) => {
                        return (
                            item.url !== "" ? (
                                <div key={index} className="sys-msg-sources-label"><a href={item.url} target="_blank" rel="noreferrer" >{item.title}</a></div>
                            ) : (
                                <div key={index} className="sys-msg-sources-label">{item.title}</div>
                            ))
                    })}
                </div>
            </div>
        </>
    );
};
