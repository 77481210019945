import { catchError } from "./common";
import axios from "axios";

export const getChatHistory = async (userId: string, timeZone: string): Promise<any> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}v1/ai/chat/users/${userId}/conversations?timezone=${timeZone}`
    );
    return response.data.data;
  } catch (e: any) {
    console.log(e);
    catchError(e);
    //return not used, keep typescript happy
    let retObj: any;
    return retObj;
  }
}

export const getActiveChatHistoryRecord = async (sessionID: string): Promise<any> => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}v2/ai/chat/session/${sessionID}`)
      .catch((error) => {
        return error?.response;
      });
    if (response.status === 403 || response.status === 404) {
      return response;
    }
    return response?.data.data;
  } catch (e: any) {
    console.log(e);
    catchError(e);
  }
}

export const updateSessionTitleForRename = async (sessionID: string, sessionTitle: string): Promise<any> => {
  const data = {
    session_id: sessionID,
    title: sessionTitle
  }
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_DOMAIN}v1/ai/chat/title:rename`, data);
    return response.data.data;
  } catch (e: any) {
    console.log(e);
    catchError(e);
  }
}

export const deleteChatHistoryRecord = async (sessionID: string): Promise<any> => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_DOMAIN}v1/ai/chat/conversation/session/${sessionID}`);
    return response.data;
  } catch (e: any) {
    console.log(e);
    catchError(e);
  }
}

export const archiveChatHistoryRecord = async (sessionID: string): Promise<any> => {
  const data = {
    "session_id": sessionID
  }
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_DOMAIN}v1/ai/chat/archive`, data);
    return response.data;
  } catch (e: any) {
    console.log(e);
    catchError(e);
  }
}

export const generateChatTitle = async (input: string, output: string, sessionID: string): Promise<any> => {
  const data = {
    "input_query": input,
    "output_response": output,
    "chat_session": sessionID
  }
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}v1/ai/chat/title:generate`, data);
    return response.data;
  } catch (e: any) {
    console.log(e);
    catchError(e);
  }
}