import { useEffect, useState } from 'react';
import { getDashboardToken } from '../services/dashboard';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

const Dashboard = ({ user }) => {

    const [reportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
        type: 'report',
        embedUrl: undefined,
        tokenType: models.TokenType.Embed,
        accessToken: undefined,
        settings: {
            layoutType: models.LayoutType.Custom,
            customLayout: {
              displayOption: models.DisplayOption.FitToWidth,
              reportAlignment: models.ReportAlignment.Center
            },
            panes: {
              pageNavigation: {
                visible: false
              }
            },
          }
    });

    const [accessToken, setAccessToken] = useState<string>('');

    useEffect(() => {
        (async () => {
            let response;
            try {
                response = await getDashboardToken();
            } catch (err) {
                console.error("error fetching dashboard details", err);
            }
            setAccessToken(response.token);
        })();
    }, []);

    useEffect(() => {
        setReportConfig(reportConfig => ({
            ...reportConfig,
            embedUrl: `${process.env.REACT_APP_POWERBI_EMBED_URL}`,
            accessToken: accessToken,
          }));
    }, [accessToken])

    return (
        <div className='h-100'>
            <PowerBIEmbed embedConfig={reportConfig} cssClassName='h-100'/>
        </div>
    );
};

export default Dashboard;
