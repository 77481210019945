import { Outlet } from 'react-router-dom';
import { ChatContext } from '../context/ChatContext';
import { useContext } from 'react';
import ErrorComponent from '../components/common/ErrorComponent';

const ProtectedRoute = ({ children }) => {

  const { allowedRoutes } = useContext(ChatContext);

  // Check if the current route is allowed
  const isRouteAllowed = allowedRoutes?.includes(window.location.pathname);
  // If the route is allowed, render the children
  if (isRouteAllowed) {
    return children ? children : <Outlet />;
  }

  // Otherwise, render the error component
  return <ErrorComponent />
};

export default ProtectedRoute;
