import React, { useState, useRef, useEffect, useContext } from "react";
import { Button, Form } from 'react-bootstrap';
import { updateSessionTitleForRename } from "../../services/history";
import { ChatHistoryContextMenu } from "./ChatHistoryContextMenu";
import { useHistoryFunctions } from "../../providers/historyProvider";
import { formatResponse } from "../../utils/chat";
import { ChatContext } from "../../context/ChatContext";

export const ChatHistoryDisplay = (props) => {

    const { data, fetchHistoryRecord } = props;
    const { setIsNewSession, isPrivateChat } = useContext(ChatContext);
    const [title, setTitle] = useState<string>("");
    const historyFunctions = useHistoryFunctions();
    const pathname = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
    if (!historyFunctions) {
        throw new Error('History functions are not available.');
    }
    const { renameSessionTitle } = historyFunctions;
    const [showInputForRename, setShowInputForRename] = useState(false);

    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (showInputForRename && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(title.length, title.length);
        }
        //eslint-disable-next-line
    }, [showInputForRename]);

    const handleSaveTitle = () => {
        if (title === "") {
            setTitle(formatResponse(data.chat_history_title, true));
        }
        else if (formatResponse(data.chat_history_title, true) !== title) {
            const newTitle = title?.substring(0, 100);
            renameSessionTitle(data?.session_id, newTitle);
            updateSessionTitleForRename(data?.session_id, newTitle);
        }
        setShowInputForRename(false);
    };

    const handleInputBlur = () => {
        handleSaveTitle();
    };

    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSaveTitle();
        }
    };

    const changeTitle = (e) => {
        setTitle(e.target.value);
    }

    const handleHistoryRecordClick = () => {
        setIsNewSession?.(false);
        fetchHistoryRecord(data.session_id);
    }

    return (
        <div className={`chat-history-display ${pathname === data?.session_id || data?.is_new_chat ? 'active-state' : ''}`} id={`chat_${data.session_id}`} >
            {showInputForRename ?
                <Form.Control
                    className="input-for-rename"
                    value={title}
                    ref={inputRef}
                    maxLength={100}
                    onChange={(e) => changeTitle(e)}
                    onBlur={handleInputBlur}
                    onKeyDown={handleInputKeyDown}
                />
                :
                <>
                    <Button disabled={isPrivateChat ? true : false} title={data.chat_history_title ? formatResponse(data.chat_history_title, true) : "New Chat"} className='history-title-button-text' onClick={() => !data?.is_new_chat ? handleHistoryRecordClick() : ""}>
                        {data.chat_history_title ? formatResponse(data.chat_history_title, true) : "New Chat"}
                    </Button>
                    {!data?.is_new_chat && (
                        <ChatHistoryContextMenu chatConversationMetadata={data} setTitle={setTitle} setShowInputForRename={setShowInputForRename} pathname={pathname} />
                    )}
                </>
            }
        </div>

    );
};
