import Card from "react-bootstrap/Card";
import "./style.scss";

import elyisa_logo from "../../assets/Elysia-logo.svg";

const RestrictedSessionErrorMessage = (props) => {

    const { isRestrictedChatSessionStatus } = props;
    let restrictionMessage = "does not belong to you.";
    if (isRestrictedChatSessionStatus === 404) {
        restrictionMessage = "does not exist.";
    }

    return (
        <div className="sys-msg-body mb-4">
            <Card className="sys-msg-card shadow-sm bg-white rounded response-card">
                <Card.Body className="custom-sys-card-body">
                    <Card.Title>
                        <img
                            className="sys-msg-card-img"
                            src={elyisa_logo}
                            alt="icon"
                        />
                    </Card.Title>
                    <Card.Text
                        className="sys-msg-card-desc"
                    >
                        The chat you tried to access {restrictionMessage} Please select a <a href="/">new chat</a>
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
};

export default RestrictedSessionErrorMessage;