import React from "react";
import { Modal, Button } from "react-bootstrap";
import icon_elysia_brain from "../../assets/icon-elysia-brain.svg";

interface UpdateProfileModalProps {
    show: boolean;
    hideProfileUpdateModal: () => void;
    isValidUrl: boolean;
    regenerateProfile: () => void;
}

const UpdateProfileModal = ({ show, hideProfileUpdateModal, isValidUrl, regenerateProfile }: UpdateProfileModalProps) => {
    return (
        <Modal
            show={show}
            onHide={hideProfileUpdateModal}
            keyboard={false}
            dialogClassName="elysia-modal"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Update Profile</Modal.Title>
            </Modal.Header>
            {isValidUrl ? (
                <Modal.Body>
                    <p><img src={icon_elysia_brain} alt="Elysia" /> You have chosen to regenerate, previous setting will be replaced.</p>
                    <p>All profile data will be updated, do you wish to continue?</p>
                </Modal.Body>
            ) : (
                <Modal.Body>
                    <p>Please enter a valid LinkedIn URL to proceed.</p>
                </Modal.Body>
            )}
            {isValidUrl && (
                <Modal.Footer className="justify-content-between">
                    <Button variant="secondary" className="light-with-border" onClick={hideProfileUpdateModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" className="light-with-border" onClick={regenerateProfile}>Proceed</Button>
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default UpdateProfileModal;
