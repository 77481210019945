import { catchError } from "./common";
import axios from "axios";

export const logFeedback = async (runId: string, feedbackType: string): Promise<any> => {
  let retObj: any;
  try {
    await axios.post(`${process.env.REACT_APP_API_DOMAIN}v1/ai/feedback/submit`, {
        run_id:runId,        
        key:feedbackType,
        score: true,
        value: 1
      },
    );
    return retObj;
  } catch (e: any) {
    console.error(e);
    catchError(e);
    //return not used, keep typescript happy
    return retObj;
  }
};


export const logCommentFeedback = async (runId: string, feedbackType: string, comment: string): Promise<any> => {
  let retObj: any;
  try {
    await axios.post(`${process.env.REACT_APP_API_DOMAIN}v1/ai/feedback/comment`, {
        run_id:runId,        
        key:feedbackType,
        score: true,
        value: 1,
        comment
      },
    );
    return retObj;
  } catch (e: any) {
      console.error(e);
      catchError(e);
    }
};
