import { useContext } from "react";
import Form from 'react-bootstrap/Form';
import { ChatContext } from "../../context/ChatContext";
import { ReactComponent as SearchIcon } from "../../assets/icon-google-search.svg";

import icon_reload from "../../assets/icon-google-reload.svg";

import "./style.scss";

const CollectionFilterContainer = (props) => {
    const { collection, reloadCollectionSources, onCollectionChange, sortOrder, register, searchTerm, setSearchTerm } = props;
    const { allowedCollections } = useContext(ChatContext);

    const handleSearch = (e: any) => {
        setSearchTerm(e.target.value);
        reloadCollectionSources(collection, e.target.value, sortOrder);
    }

    const handleSearchKeyDown = (e: any) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (e.target.value !== searchTerm)
                handleSearch(e)
        }
    };

    const handleSearchBlur = (e: any) => {
        e.preventDefault();
        if (e.target.value !== searchTerm)
            handleSearch(e);
    };

    return (
        <div className="d-flex">
            <div className="search-collection-input-wrapper">
                <span className="search-icon"><SearchIcon /></span>
                <Form.Control
                    {...register("search-text")}
                    className="search-collection-input"
                    type="text"
                    placeholder="search collection"
                    onKeyDown={handleSearchKeyDown}
                    data-testid="search-collection-input"
                    onBlur={handleSearchBlur}
                />
            </div>
            <div className="d-flex collection-dropdown-wrapper">
                <div className="collection-dropdown-label">
                    Type:
                </div>
                <div>
                    <Form.Select
                        aria-label="collection"
                        className="collection-dropdown"
                        {...register("collection-list")}
                        onChange={onCollectionChange}
                        data-testid="search-collection-dropdown"
                        autoComplete="off"
                    >
                        <option value="private||My Private Collection|myCollection">My Private Collection</option>
                        {allowedCollections?.filter(collection => collection.perm === "manage").map(({ ckey, cline, ctype, cname }, index) => <option key={index} value={ctype + '|' + cline + '|' + cname + '|' + ckey} >{cname}</option>)}
                    </Form.Select>
                </div>
                <div>
                    <img src={icon_reload} className="refresh-collection" onClick={() => reloadCollectionSources(collection, searchTerm, sortOrder)} alt="refresh" title="Refresh" />
                </div>
            </div>
        </div >
    );
};

export default CollectionFilterContainer;
