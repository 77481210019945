import icon_upload from "../../assets/icon-upload-transparent.svg";

import "./style.scss";

const ManageContentHeader = (props) => {
    const { setPageState } = props;

    return (
        <>
            <div className="title1" data-testid="manage-content">Manage Content</div>
            <div className="gap">&nbsp;</div>
            <div className="p4 mb-4" data-testid="manage-content-sub-heading">
                You can upload and manage files here to be included in your
                conversations.
            </div>
            <button
                className="content-upload-btn color-elysia-sky mb-1 mt-4"
                data-testid="upload-to-collection-button"
                aria-label="upload to collection"
                onClick={() => {
                    setPageState("upload");
                }}
            >
                <img src={icon_upload} alt="upload" /> &nbsp; Upload to collection
            </button>
        </>
    );
};

export default ManageContentHeader;
