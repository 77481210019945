declare global {
    interface Window {
        IIRISTracker: any;
    }
}

const portalEvt = 'ElysiaAIEvent';

function sendHubEvent(action: string, label: string, prop: string) {
    window.IIRISTracker.trackStructEvt({
        cat: portalEvt,
        act: action,
        lbl: label,
        prop: prop,
        val: 1.0
    });
}

const setTrackingId = (uid: string) => {
    window?.IIRISTracker?.setTrackingUID &&
        window?.IIRISTracker?.setTrackingUID(uid);
};

const clearTrackingUID = () => {
    window?.IIRISTracker?.clearTrackingUID &&
        window?.IIRISTracker?.clearTrackingUID();
};

export const trackEvent = (action: string, label: string, prop: string): void => {
    trackerCallBackHandler(sendHubEvent, [action, label, prop]);
};

export const trackId = (uid: string) => {
    trackerCallBackHandler(setTrackingId, [uid]);
}

export const clearTrackId = () => {
    trackerCallBackHandler(clearTrackingUID, []);
}

export const trackerCallBackHandler = (callback: any, args: any[]) => {
    if (window?.IIRISTracker) {
        callback && callback.call(null, ...args);
    } else {
        window.addEventListener("trackerInitialized", function (evt) {
            if (window?.IIRISTracker) {
                callback && callback.call(null, ...args);
            }
        });
    }
};