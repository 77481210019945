import { useEffect, FC, useState, useContext } from 'react';
import  { HistoryDataContext , HistoryFunctionsContext } from '../context/HistoryContext';
import { getChatHistory } from '../services/history';

interface Props {
    children: JSX.Element;
    user: any;
}

export const HistoryProvider: FC<Props> = ({ children, user }) => {
    const [chatHistoryData, setChatHistoryData] = useState<HistoryContextStore | undefined>(undefined);
    const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const getUserData = async () => {
        setChatHistoryData(await getChatHistory(user.signInUserSession.idToken.payload.sub, timezone));
    };

    const addChatToToday = (newChat: Chat) => {
        setChatHistoryData((prevChatHistoryData) => {
            if (!prevChatHistoryData) return prevChatHistoryData;
            return {
                ...prevChatHistoryData,
                today: [ newChat, ...prevChatHistoryData.today]
            };
        });
    };

    const updateChatHistoryOnDelete = (session_id: String) => {
        setChatHistoryData((chatHistoryData) => {
            if (!chatHistoryData) return chatHistoryData;
            for (let key in chatHistoryData) {
                if (chatHistoryData.hasOwnProperty(key) && chatHistoryData[key].length) {
                    chatHistoryData[key] = chatHistoryData[key].filter((conversation) => conversation.session_id !== session_id);
                }
            }

            return {
                ...chatHistoryData,
            };
        });
    };

    const updateChatHistoryOnArchive = (session_id: String) => {
        setChatHistoryData((chatHistoryData) => {
            let archivedConversation!: Chat;
            if (!chatHistoryData) return chatHistoryData;
            for (let key in chatHistoryData) {
                if (key !== "archived_chats" && chatHistoryData.hasOwnProperty(key) && chatHistoryData[key].length) {
                    // eslint-disable-next-line
                    chatHistoryData[key] = chatHistoryData[key].filter((conversation) => {
                        if (conversation.session_id === session_id) {
                            archivedConversation = conversation;
                            archivedConversation["is_archived"] = true;
                        }
                        return conversation.session_id !== session_id;
                    });
                }
            }
            return {
                ...chatHistoryData,
                archived_chats: [ archivedConversation, ...chatHistoryData.archived_chats]
            };
        });
    };

    const updateSessionTitle = async (sessionId: string, newTitle: string) => {
        const titleCharacters = newTitle.split('');
        let generatedTitle = '';
        for (let i = 0; i < titleCharacters.length; i++) {
            await new Promise(resolve => setTimeout(resolve, 100));
            generatedTitle += titleCharacters[i];
            // eslint-disable-next-line
            setChatHistoryData((prevChatHistoryData) => {
                // eslint-disable-next-line
                if (!prevChatHistoryData) return prevChatHistoryData;
                const updatedChats = prevChatHistoryData.today.map(chat => {
                    if (chat.session_id === sessionId) {
                        return { ...chat, chat_history_title: generatedTitle, is_new_chat: false };
                    }
                    return chat;
                });
                return {
                    ...prevChatHistoryData,
                    today: updatedChats
                };
            });
        }
    };

    const renameSessionTitle = async (sessionId: string, newTitle: string) => {
        setChatHistoryData((prevChatHistoryData) => {
            if (!prevChatHistoryData) return prevChatHistoryData;
    
            const updatedData: Partial<HistoryContextStore> = {};
    
            Object.keys(prevChatHistoryData).forEach(bucket => {
                const chats = prevChatHistoryData[bucket as keyof HistoryContextStore];
                if (Array.isArray(chats)) {
                    const updatedChats = chats.map(chat => (
                        chat.session_id === sessionId ? { ...chat, chat_history_title: newTitle } : chat
                    ));
                    updatedData[bucket as keyof HistoryContextStore] = updatedChats;
                }
            });
    
            return { ...prevChatHistoryData, ...updatedData };
        });
    };
    
    useEffect(() => {
        getUserData();
        // eslint-disable-next-line
    }, []);

    return (
        <HistoryDataContext.Provider value={chatHistoryData ?? { today: [], yesterday: [], last_7_days: [], last_30_days: [], archived_chats: [] }}>
            <HistoryFunctionsContext.Provider value={{ addChatToToday, updateSessionTitle, renameSessionTitle, updateChatHistoryOnDelete, updateChatHistoryOnArchive }}>
            {user ? children : ''}
            </HistoryFunctionsContext.Provider>
        </HistoryDataContext.Provider>
    );
};

export const useHistoryData = () => useContext(HistoryDataContext);
export const useHistoryFunctions = () => useContext(HistoryFunctionsContext);