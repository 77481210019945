
import React, { useState, useEffect, FC } from 'react';

import { SettingContext } from '../context/SettingContext';

import { getAppById } from "../services/apps";

interface Props {
    children: JSX.Element,
    user: any
}

export const SettingProvider:FC<Props> = ({ children, user}) => { 
    const [defaultAppInfo, setDefaultAppInfo] = useState<AppType | null >(null);
    const [modelProvider, setModelProvider] = useState<string>("azure");
    const [modelName, setModelName] = useState<string>("gpt-4");
    const [personality, setPersonality] = useState<string>("Professional");
    const [creativity, setCreativity] = useState<string>("Factual");
    const [role, setRole] = useState<string>("Assistant");
    const [domainExpertise, setDomainExpertise] = useState<string>("General");
    const [writingStyle, setWritingStyle] = useState<string>("Descriptive");
    const [outputLanguage, setOutputLanguage] = useState<string>("English");
    const [showSourceList, setShowSourceList] = useState<boolean>(false);

    useEffect(() => {
        const fetchDefaultAppInfo = async () => {     
            setDefaultAppInfo(await getAppById(`${process.env.REACT_APP_ELYSIA_APP_ID}`));
        };
        fetchDefaultAppInfo();
        if (defaultAppInfo?.settings){
            const settings = JSON.parse(defaultAppInfo?.settings);
            setModelProvider(settings.model_provider)
            setModelName(settings.name_of_model);
            setPersonality(settings.personality)
            setCreativity(settings.creativity)
            setRole(settings.role)
            setDomainExpertise(settings.domain_expertise)
            setWritingStyle(settings.writing_style)
            setOutputLanguage(settings.output_language)
            setShowSourceList(settings.show_source_list)
        }
    }, [defaultAppInfo?.settings]); 


    const settingContext: SettingContextStore = {    
        modelProvider,
        modelName,   
        personality,
        creativity,
        role,
        domainExpertise,
        writingStyle,
        outputLanguage,
        showSourceList,   
        setModelProvider,
        setModelName,   
        setPersonality,
        setCreativity,
        setRole,
        setDomainExpertise,
        setWritingStyle,
        setOutputLanguage,
        setShowSourceList,
      };

      
    return (
        <SettingContext.Provider value={settingContext}>
            { user ? 
                 children
                : ''
            }
        </SettingContext.Provider>
    )
}
