import React, { useState, ReactElement, useContext } from 'react';
import { OverlayTrigger, Tooltip, Button, OverlayTriggerProps } from 'react-bootstrap';
import { AppContext } from '../../../context/AppContext';

interface OverlayTooltipProps extends Omit<OverlayTriggerProps, 'overlay' | 'children'> {
    tooltipText: string;
    buttonContent: string | ReactElement;
    ariaLabel: string;
    testId: string;
    tooltipClassName: string;
    buttonClassName: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    timeoutDuration?: number;
    disabled?: boolean;
}

const OverlayTooltip = ({
    tooltipText,
    buttonContent,
    ariaLabel,
    testId,
    tooltipClassName,
    buttonClassName,
    onClick,
    timeoutDuration = 1000,
    disabled = false,
    placement = 'bottom-start',
    ...otherProps
}: OverlayTooltipProps) => {
    const [show, setShow] = useState<boolean>(false);
    const { isTouchDevice } = useContext(AppContext)

    const handleMouseEnter = () => {
        if (!isTouchDevice) {
            setShow(true);
        } 
    };
    const handleMouseLeave = () => {
        if (!isTouchDevice) {
            setShow(false);
        }
    };
    const handleTouchStart = () => setShow(true);
    const handleTouchEnd = () => setShow(false);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
            onClick(event);
        }
    };

    return (
        <OverlayTrigger
            placement={placement}
            show={show}
            overlay={<Tooltip className={tooltipClassName}>{tooltipText}</Tooltip>}
            {...otherProps}
        >
            <Button
                aria-label={ariaLabel}
                data-testid={testId}
                className={buttonClassName}
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                disabled={disabled}
            >
                {buttonContent}
            </Button>
        </OverlayTrigger>
    );
};


export default OverlayTooltip;